import React, {useEffect, useReducer} from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './Containers/Header'
import VerifyAccount from './Containers/VerifyAccount'
import Authenticated from './Containers/Authenticated'
import LinkStrongID from './Containers/LinkStrongID'
import { StateContext, DispatchContext } from './common/context'
import { reducer, initialState } from './common/reducer'
import query from 'query-string'
import { Auth0Provider } from "./common/auth0"
import history from './common/history'
import { setError } from './common/actions'
import { StrongFlowCallBack, StrongFlowStart } from './Containers/activation-flows/StrongFlow'
import { TotpFlowCallBack, TotpFlowStart } from './Containers/activation-flows/TotpFlow'

// A function that routes the user to the right place
// after login
const onRedirectCallback = () => {
  history.push(
    window.location.pathname
  );
};

type RouteParams = {}

const App = (props: RouteParams): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let qs = query.parse(window.location.search)
    if (qs.error) {
      dispatch(setError({ title: `Error: ${qs.error}`, description: qs.error_description }))
    }
  }, [])

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : ''}
          client_id={process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : ''}
          redirect_uri={window.location.origin}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          onRedirectCallback={onRedirectCallback}
        >
          <Router>
            {process.env.REACT_APP_APPLICATION === 'access' ?
              <Switch>
                <Route exact path="/">
                  <React.Fragment>
                    <Header />
                    <Authenticated />
                  </React.Fragment>
                </Route>
              </Switch>
              :
              <Switch>
                <Route exact path="/link">
                  <React.Fragment>
                    <LinkStrongID />
                  </React.Fragment>
                </Route>
                <Route exact path="/verify">
                  <VerifyAccount />
                </Route>
                <Route exact path="/verify/strong" >
                  <StrongFlowStart />
                </Route>
                <Route exact path="/verify/strong/callback" >
                  <StrongFlowCallBack />
                </Route>
                <Route exact path="/verify/totp" >
                  <TotpFlowStart />
                </Route>
                <Route exact path="/verify/totp/callback" >
                  <TotpFlowCallBack />
                </Route>
              </Switch>
            }
          </Router>
        </Auth0Provider>
      </StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export default App
