import React, { useContext, useEffect, useState } from 'react'
import { AmUserDTO, Customer, newUser, TableSorting } from '../../common/types'
import * as api from '../../common/api'
import { DispatchContext, StateContext } from '../../common/context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import UserModal from './UserModal'
import Notifications from '../../Components/Notifications'
import { addNotification, setAllPreLoadData, setGlobalLoading, setSelectedUser, setUsers } from '../../common/actions'
import { handleErrorMessage, processUserFilterInput } from '../../common/helper'
import SearchInput from '../../Components/SearchInput'
import UsersTable from '../../Components/UsersTable'

export type SortByParams = (tableSorting: TableSorting, formatFn?: (s: string | undefined) => string) => () => void

export type onUserClickedParams = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, user: AmUserDTO) => Promise<boolean>

const UserAccessContainer = () => {
  const { token, globalLoading } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const [isUserVisible, setUserVisible] = useState<boolean>(false)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [filterInput, setFilterInput] = useState<string | undefined>(undefined)
  const [wildcardError, setWildcardError] = useState<string>('')
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [hasShowStoppingError, setShowStoppingError] = useState<boolean>(false)

  const onUserClicked: onUserClickedParams = async (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, user: AmUserDTO) => {
    // Skip when selecting text on the row
    if (window.getSelection()?.type === 'Range') {
      return false
    }
    setSelectedUserId(user.id)
    setUserVisible(true)
    return true
  }

  const refreshUsersByFilter = (filterText: string) => {
    console.log('refresh users by filter')
    setWildcardError('')
    dispatch(setGlobalLoading(true))

    const { searchClause, postFilterFn, roleFilter } = processUserFilterInput(filterText)
    console.log('roleFilter', roleFilter)
    api.getUsers(token, searchClause, roleFilter).then(usersVal => {
      // if(usersVal.error) {
      if(!Array.isArray(usersVal)) {
        setWildcardError(usersVal.error)
        dispatch(setUsers([]))
      } else {
        dispatch(setUsers(
          usersVal ? postFilterFn(usersVal) : usersVal
        ))
      }
    }).catch(async (err) => {
      console.log(`couldn't filter users`, err)
      dispatch(addNotification({ message: await handleErrorMessage(err, `Error while filtering users`), isError: true }))
    }).finally(() => {
      dispatch(setGlobalLoading(false))
    })
  }

  const getAllRoles = async () => {
    try {
      return await api.getAllRoles(token)
    } catch (err) {
      console.log('unable to load all roles', err)
      dispatch(addNotification({ message: await handleErrorMessage(undefined, `Error while fetching all roles, please refresh page`), isError: true, isSticky: true }))
      setShowStoppingError(true)
      return []
    }
  }

  const getAllCustomers = async (): Promise<Customer[]> => {
    try {
      return await api.getCustomers(token)
    } catch (err) {
      console.log('unable to load all customers', err)
      dispatch(addNotification({ message: await handleErrorMessage(undefined, `Error while fetching all customers, please refresh page`), isError: true, isSticky: true }))
      setShowStoppingError(true)
      return []
    }
  }

  const getAllTenants = async () => {
    try {
      return await api.getTenants(token)
    } catch (err) {
      console.log('unable to load all tenants', err)
      dispatch(addNotification({ message: await handleErrorMessage(undefined, `Error while fetching all tenants, please refresh page`), isError: true, isSticky: true }))
      setShowStoppingError(true)
      return []
    }
  }

  const getAllApplications = async () => {
    try {
      return await api.getApplications(token)
    } catch (err) {
      console.log('unable to load all applications', err)
      dispatch(addNotification({ message: await handleErrorMessage(undefined, `Error while fetching all applications, please refresh page`), isError: true, isSticky: true }))
      setShowStoppingError(true)
      return []
    }
  }

  const getLookAndFeelIds = async () => {
    try {
      return await api.getLookAndFeelIds(token)
    } catch (err) {
      console.log('unable to load look and feel ids', err)
      dispatch(addNotification({ message: await handleErrorMessage(undefined, `Error while fetching look and feel ids, please refresh page`), isError: true, isSticky: true }))
      setShowStoppingError(true)
      return []
    }
  }

  const getAllUsers = async (): Promise<AmUserDTO[]> => {
    try {
      const allUsers = await api.getUsers(token)
      if (Array.isArray(allUsers)) {
        // We're setting local state immediately, so we have the users available sooner
        return allUsers
      } else {
        setWildcardError(allUsers.error)
        return []
      }
    } catch (err) {
      console.log(`couldn't load users`, err)
      dispatch(addNotification({ message: await handleErrorMessage(undefined, `Error while loading users`), isError: true }))
      return []
    }
  }

  const loadData = async () => {
    //check(token)
    // Load all users to local state and utility data to global state while waiting for all of them
    const promises = await Promise.all([/*getAllUsers(), */getAllRoles(), getAllCustomers(), getAllTenants(), getAllApplications(), getLookAndFeelIds()])
    dispatch(setAllPreLoadData(promises))
    dispatch(setGlobalLoading(false))
  }

  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      console.log('closing modal with esc')
      closeModal(false)
    }
  }

  useEffect(() => {
    if (token !== "") {
      loadData()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [isUserVisible])

  const showInviteUserDialog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSelectedUserId('')
    setUserVisible(true)
    event.preventDefault()
  }

  const onFilterChange = (value: string) => {
    console.log('filter change', value)
    setFilterInput(value)
    refreshUsersByFilter(value)
  }

  const closeModal = (refreshUsers?: boolean) => {
    console.log('isUserVisible', isUserVisible)
    if (isUserVisible) {
      setUserVisible(false)
      dispatch(setSelectedUser(newUser()))
      setSelectedUserId('')
      if (refreshUsers) {
        console.log('Refreshing users upon request...')
        refreshUsersByFilter(filterInput || '')
      }
    }
  }

  return (
    <div className="box access-container" /*style={{ height: 'inherit' }}*/>
      {!hasShowStoppingError ? (
        <>
          <UserModal userId={selectedUserId} isVisible={isUserVisible} closeModal={closeModal} />

          <div className="field">
            <div className="control">
              <button className="button" onClick={showInviteUserDialog} disabled={globalLoading}>
                <span className="icon"><FontAwesomeIcon icon={faPlusSquare} /></span>
                <span>Create new user access invitation</span>
              </button>
            </div>
          </div>

          <div className="is-flex" style={{ width: "100%", maxWidth: '700px' }}>
            <div className="field has-addons" style={{ width: "100%", maxWidth: '400px' }}>
              <SearchInput searchLoading={searchLoading} onChange={onFilterChange} autoFocus disabled={globalLoading} />
            </div>
            <div className="field ml-3">
              <div className="control">
                <button className="button" onClick={() => onFilterChange('')} disabled={globalLoading}>
                  Show all users
                </button>
              </div>
            </div>
            <div style={{ zIndex: 50 }} className="is-align-self-center ml-3 mb-3 icon is-small tooltip has-tooltip-multiline has-tooltip-text-centered has-tooltip-arrow has-tooltip-right"
                 data-tooltip="You can search by name and email fields in free text. You can search for users with a specific tenant by using the 'tenant:' prefix. You can search for users with a specific role by using the 'role:' prefix (add * for wildcard). Other search fields available are: 'status:', 'account type:', 'id:'. You can also search for customers by using the 'accesses:' prefix (supports operators like '|' and can be preceded by other criteria like tenant and free text).">
              <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} className="has-text-grey-lighter" />
            </div>
          </div>
          <div className="field has-addons">
            <div style={{ flexBasis: '100%', marginTop: '-0.75rem' }} className="">{wildcardError}</div>
          </div>

          <UsersTable onUserClicked={onUserClicked} clickDisabled={globalLoading} filterInput={filterInput} />
        </>
      ) : (
        <h2 className="title is-6">
          Cannot load application, try refreshing the page or contact support.
        </h2>
      )}
      <Notifications />
    </div>
  )
}

export default UserAccessContainer
