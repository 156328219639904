import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import query from 'query-string'
import { verifyAccount } from '../common/api'
import ErrorPage from './Error'
import { DispatchContext, StateContext } from '../common/context'
import { setError } from '../common/actions'

export interface LStorageI {
  code: string
  pin: string
}

type RouteParams = {

}

const setValues = (val: LStorageI) => {
  localStorage.setItem('verify', JSON.stringify(val))
}

export const getValues = (): LStorageI | undefined => {
  let qs = query.parse(window.location.search)

  // Query strings takes predence
  if (qs.vc) {
    return { code: qs.vc as string, pin: "" }
  }

  // Try to get values from local storage
  let val = localStorage.getItem('verify')
  if (!val) return undefined
  return JSON.parse(val)
}

const VerifyAccount = (): React.ReactElement => {
  const { error } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [verifying, setVerifying] = useState<boolean>(false)
  const [verified, setVerified] = useState<boolean>(false)
  const [pin, setPin] = useState<string>("")

  const vals = getValues()

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      dispatch(setError(undefined));
    }

    if (verified) {
      setVerified(false)
    }

    setPin(e.target.value)
  }

  const onVerifySubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (vals && !verifying) {
      // Store given values in localStorage
      setValues({code: vals.code, pin: pin,})
      setVerifying(true)
      verifyAccount(vals.code, pin).then(res => {
        setVerifying(false)
        setVerified(true)
      }).catch(async (err) => {
        console.log('error verify', err)
        setVerifying(false)
        const description = err.response ? await err.response.text() + '. Please contact support.' : 'Please contact support'
        dispatch(setError({title: 'Error', description}))
      })
    } else if (!vals) {
      dispatch(setError({ title: 'Error', description: 'Code is missing, please close the window and use the original link to access the page' }))
    }

    //loginWithRedirect({redirect_uri: window.location.href})
  }

  useEffect(() => {
    if (vals && vals.pin) {
      setPin(vals.pin)
    }
  }, [vals])

  /*
  if (loading) {
    return (<progress className="progress is-small is-primary" max="100">loading...</progress>)
  }
  */

  // Please close this page, and use original link to account verification.
  if (error) {
    return <ErrorPage error={error.title} description={error.description} />
  }

  if (verified) {
    return <SelectFlow />
  }

  return (
    <div className="container activate-container">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            <img src={process.env.PUBLIC_URL + '/favicons/favicon-2-32x32.png'} alt="logo" width="32" height="32" style={{ marginRight: '10px' }} />
            Verify Account
          </p>
        </header>
        <div className="card-content">
          <div className="columns" onSubmit={onVerifySubmit}>
            <div className="column is-full is-vcentered">
              <p>
                Your account needs to be verified before you can login. Please enter the pin code sent via SMS. If you have not received a pin code, please contact support.
                If you have already verified your account, you may proceed to login using the link provided to you in the invitation email.
              </p>
            </div>
          </div>
          <form className="columns" onSubmit={onVerifySubmit}>
            <div className="column is-2 is-vcentered">
              <div className="field is-horizontal">

                <div className="field-label is-small">
                  <label className="label">Pin:</label>
                </div>

                <div className={"control is-small" + (verifying ? ' is-loading' : '')}>
                  <input disabled={verifying} autoFocus className={"input is-small" + (error ? ' is-danger' : '') + (verified ? ' is-success' : '')} type="text" maxLength={8} value={pin} onChange={onCodeChange} />
                </div>
              </div>
            </div>
            <div className="column">
              <button disabled={verifying} className="button is-small">Verify</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const SelectFlow = (): React.ReactElement => {
  const { error } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const history = useHistory()

  const startStrongFlow = () => {
    history.push('/verify/strong')
  }

  const startTotpFlow = () => {
    history.push('/verify/totp')
  }

  // Please close this page, and use original link to account verification.
  if (error) {
    return <ErrorPage error={error.title} description={error.description} />
  }

  return (
    <div className="container activate-container">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            Continue with...
          </p>
        </header>
        <div className="card-content">
          <div className="columns is-multiline">
            <div className="column is-full">
              <FlowSelectCard
                isRecommended={true}
                title="Bank ID"
                description1="I have Finnish Online Banking Codes or Finnish Mobile ID"
                description2="I have Swedish BankID"
                onClick={startStrongFlow}
                selectionIcon={<BankIcon />}
              />
            </div>
            <div className="column is-full">
              <FlowSelectCard
                isRecommended={false}
                title="Authenticator App"
                description1="I don't have any of the above and I want to create an account using a unique password and mobile authenticator app"
                onClick={startTotpFlow}
                selectionIcon={<ShieldIcon />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FlowSelectCard = ({ isRecommended, title, description1, description2, description3, onClick, selectionIcon  }: { isRecommended: boolean, title: string, description1: string, description2?: string, description3?: string, onClick: () => void, selectionIcon: React.ReactNode  }) => {
  return (
    <button className={"button is-fullwidth"} style={{height: 'auto', borderStyle: isRecommended ? 'solid' : 'dashed'}} onClick={onClick}>
      <span className="icon is-pulled-left" style={{height: '3rem', width: '3rem'}}>
        {selectionIcon}
      </span>
      <div className="columns is-mobile is-multiline is-marginless" style={{ width: '100%' }}>
        <div className="column is-full is-paddingless">
          <div className="title is-5 is-marginless is-pulled-left">
            {title}
          </div>
          {isRecommended && <span className="is-marginless heading subtitle is-pulled-right is-7 has-text-weight-bold has-text-link">RECOMMENDED</span>}
        </div>
        <div className="column is-full is-paddingless">
          <p className="heading has-text-left is-marginless" style={{whiteSpace: 'initial'}}>
            {description1}
          </p>
          {description2 && (
            <p className="heading has-text-left is-marginless" style={{whiteSpace: 'initial'}}>
              {description2}
            </p>
          )}
          {description3 && (
            <p className="heading has-text-left is-marginless" style={{whiteSpace: 'initial'}}>
              {description3}
            </p>
          )}
        </div>
      </div>
      <span className="icon is-small is-pulled-right">
        <ArrowIcon />
      </span>
    </button>
  )
}

const BankIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><title>bank</title><g fill="#14171e"><rect x="3" y="16" width="2" height="10"></rect> <rect x="11" y="16" width="2" height="10"></rect> <rect x="19" y="16" width="2" height="10"></rect> <rect x="27" y="16" width="2" height="10"></rect> <path fill="#14171e" d="M31,28H1c-0.552,0-1,0.447-1,1v2c0,0.553,0.448,1,1,1h30c0.552,0,1-0.447,1-1v-2C32,28.447,31.552,28,31,28z "></path> <path fill="#14171e" d="M31.471,8.117l-15-8c-0.294-0.156-0.647-0.156-0.941,0l-15,8C0.204,8.291,0,8.631,0,9v4c0,0.553,0.448,1,1,1 h30c0.552,0,1-0.447,1-1V9C32,8.631,31.796,8.291,31.471,8.117z M16,10c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2 c1.105,0,2,0.895,2,2C18,9.105,17.105,10,16,10z"></path></g></svg>

const ShieldIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><title>shield</title><g fill="#14171e"><path d="M30.275,5.038l-14-4a1.008,1.008,0,0,0-.55,0l-14,4A1,1,0,0,0,1,6C1,25.479,15.517,30.89,15.664,30.941a.986.986,0,0,0,.672,0,21.293,21.293,0,0,0,7.383-5.122C27.041,22.38,31,16.148,31,6A1,1,0,0,0,30.275,5.038ZM20.224,21,16,18.779,11.776,21l.807-4.7L9.166,12.966l4.722-.686L16,8l2.112,4.28,4.722.686L19.417,16.3Z" fill="#14171e"></path></g></svg>

const ArrowIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><title>ctrl-right</title><g fill="#14171e"><path d="M11.775.592a.5.5,0,0,0-.7-.092L8.7,2.329a.5.5,0,0,0-.092.7L18.581,16,8.6,28.97a.5.5,0,0,0,.092.7L11.074,31.5a.5.5,0,0,0,.7-.092L23.4,16.305a.5.5,0,0,0,0-.61Z" fill="#14171e"></path></g></svg>

export default VerifyAccount
