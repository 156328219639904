import * as R from 'ramda'
import {
    ADD_NOTIFICATION,
    addNotification,
    AUTHENTICATED,
    authenticated,
    GOT_TOKEN,
    gotToken,
    HIDE_NOTIFICATION,
    hideNotification,
    SET_ALL_PRELOAD_DATA,
    SET_ERROR,
    SET_GLOBAL_LOADING,
    SET_SELECTED_USER,
    SET_USERS,
    setAllPreLoadData,
    setError,
    setGlobalLoading,
    setSelectedUser,
    setUsers
} from './actions'
import { AmUserDTO, Application, Customer, ErrorMessage, LookAndFeelId, newUser, Notif, Role, TenantSelect, User } from './types'
import { validateUserFields } from './helper'

export type Action = ReturnType<
    typeof authenticated |
    typeof setGlobalLoading |
    typeof gotToken |
    typeof setError |
    typeof addNotification |
    typeof hideNotification |
    typeof setAllPreLoadData |
    typeof setSelectedUser |
    typeof setUsers
>

export type State = {
    user?: User
    globalLoading: boolean
    token: string
    error?: ErrorMessage
    superAdmin: boolean
    notifications: Notif[]
    allRoles: Role[]
    allCustomers: Customer[]
    allTenants: TenantSelect[]
    allApplications: Application[]
    users: AmUserDTO[]
    selectedUser: AmUserDTO
    userErrors: string[]
    lookAndFeelIds: LookAndFeelId[]
}

export const initialState: State = {
    user: undefined,
    globalLoading: true,
    token: "",
    error: undefined,
    superAdmin: false,
    notifications: [],
    allRoles: [],
    allCustomers: [],
    allTenants: [],
    allApplications: [],
    users: [],
    selectedUser: newUser(),
    userErrors: [],
    lookAndFeelIds: []
}

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                user: action.payload,
                superAdmin: R.pathOr('', ['http://schema.jamadvisors.fi/2020/01/identity/claims/roles'], action.payload).includes('SuperAdmin')
            }
        case GOT_TOKEN:
            return { ...state, token: action.payload }
        case SET_GLOBAL_LOADING:
            return { ...state, globalLoading: action.payload }
        case SET_ERROR:
            return { ...state, error: action.payload }
        case ADD_NOTIFICATION:
            return { ...state, notifications: [...state.notifications, action.payload] }
        case HIDE_NOTIFICATION:
            return { ...state, notifications: [...state.notifications.filter(notif => notif.id !== action.payload.id), { ...action.payload, visible: false }] }
        case SET_ALL_PRELOAD_DATA:
            // Only update users if they are not already set, which can be the case if the user starts filtering the users
            // return { ...state, users: state.users?.length ? state.users : action.payload[0], allRoles: action.payload[1], allCustomers: action.payload[2], allTenants: action.payload[3], allApplications: action.payload[4] }
            return { ...state, allRoles: action.payload[0], allCustomers: action.payload[1], allTenants: action.payload[2], allApplications: action.payload[3], lookAndFeelIds: action.payload[4]}
        case SET_SELECTED_USER:
            return { ...state, selectedUser: action.payload, userErrors: validateUserFields(action.payload) }
        case SET_USERS:
            return { ...state, users: action.payload }
        default:
            throw new Error("unknown action")
    }
}
