import React, { useEffect } from 'react'
import UserAccessContainer from '../Containers/access/index'
import { DispatchContext, StateContext } from '../common/context'
import { useAuth0 } from '../common/auth0'
import { authenticated, gotToken } from '../common/actions'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Params = {
}


const Authenticated = (props: Params): React.ReactElement => {
  const state = React.useContext(StateContext)
  const dispatch = React.useContext(DispatchContext)
  const { loading, user, getTokenSilently, loginWithRedirect } = useAuth0()

  const loadToken = async (): Promise<void> => {
    const token = await getTokenSilently()
    console.log(token)
    if (token !== undefined) {
      dispatch(gotToken(token))
    } else {
      console.log("token failed")
    }
  }

  useEffect(() => {
    if (user) {
      dispatch(authenticated(user))
      loadToken()
    }
    // eslint-disable-next-line
  }, [user])

  if (loading) {
    return (<React.Fragment>loading...</React.Fragment>)
  }

  if (state.token === "") {
    return (
      <div className="has-text-centered">
        <FontAwesomeIcon
          icon={faUserLock} size={'2xl'}
          className="has-text-danger mt-6"
          data-tooltip="No access, please login"
          style={{ cursor: 'default' }}
        />
        <h1 className="has-text-centered heading is-size-6 mt-1">
          No access
        </h1>
        <button className="button mt-2 mb-6" onClick={() => loginWithRedirect({prompt:'select_account', redirect_uri: window.location.protocol + '//' + window.location.host + window.location.pathname })}>Log in</button>
      </div>
    )
  }

  return (
    <UserAccessContainer />
  )
}

export default Authenticated
