import React, { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '../../common/auth0'
import { finishTotp, requestPasswordTicket } from '../../common/api'
import ErrorPage from '../Error'
import { DispatchContext, StateContext } from '../../common/context'
import { setError } from '../../common/actions'
import { getValues, LStorageI } from '../VerifyAccount'

export const TotpFlowStart = (): React.ReactElement => {
  const { error } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { loading } = useAuth0()
  const [localLoading, setLocalLoading] = useState<boolean>(false)
  const [ticket, setTicket] = useState<string | undefined>(undefined)
  const [vals, setVals] = useState<LStorageI | undefined>(undefined)

  const requestTicket = () => {
    console.log('request ticket')
    dispatch(setError(undefined))
    setLocalLoading(true)
    requestPasswordTicket(vals!.code, vals!.pin).then(async res => {
      setTicket(res.ticket)
    }).catch(async (err) => {
      console.log('error requesting password change ticket', err)
      const description = err.response ? await err.response.text() + '. Please contact support.' : 'Please contact support'
      dispatch(setError({title: 'Error', description}))
    }).finally(() => {
      setLocalLoading(false)
    })
  }

  const openTicket = () => {
    window.location.assign(ticket!)
  }

  useEffect(() => {
    if (!vals) {
      setVals(getValues())
    } else {
      requestTicket()
    }
  }, [vals])

  useEffect(() => {
    if (ticket) {
      openTicket()
    }
  }, [ticket])

  if (localLoading || loading) {
    return (<progress className="progress is-small is-primary" max="100">loading...</progress>)
  }

  // Please close this page, and use original link to account verification.
  if (error) {
    return <ErrorPage error={error.title} description={error.description} />
  }

  if (!ticket) {
    return (
      <div className="container activate-container">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              TOTP activation
            </p>
          </header>
          <div className="card-content">
            <form className="columns">
              <div className="column is-2 is-vcentered">
                <p>Requesting ticket...</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="container activate-container">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              TOTP activation
            </p>
          </header>
          <div className="card-content">
            <div className="columns">
              <div className="column is-vcentered">
                <p>
                  Forwarding to password form...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const TotpFlowCallBack = (): React.ReactElement => {
  const { error } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [localLoading, setLocalLoading] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [vals, setVals] = useState<LStorageI | undefined>(undefined)

  const finishTotpFlow = () => {
    console.log('request login url')
    dispatch(setError(undefined))
    setLocalLoading(true)
    finishTotp(vals!.code, vals!.pin).then(async res => {
      setUrl(res.url)
    }).catch(async (err) => {
      console.log('error requesting login url', err)
      const description = err.response ? await err.response.text() + '. Please contact support.' : 'Please contact support'
      dispatch(setError({title: 'Error', description}))
    }).finally(() => {
      setLocalLoading(false)
    })
  }

  const deleteVerify = () => {
    localStorage.removeItem('verify')
  }

  const login = () => {
    deleteVerify()
    window.location.assign(`${url}?auth_method=totp`)
  }

  useEffect(() => {
    if (!vals) {
      setVals(getValues())
    } else {
      finishTotpFlow()

    }
  }, [vals])

  if (localLoading) {
    return (<progress className="progress is-small is-primary" max="100">loading...</progress>)
  }

  // Please close this page, and use original link to account verification.
  if (error) {
    return <ErrorPage error={error.title} description={error.description} />
  }

  return (
    <div className="container activate-container">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            <img src={process.env.PUBLIC_URL + '/favicons/favicon-2-32x32.png'} alt="logo" width="32" height="32" style={{ marginRight: '10px' }} />
            Account is now verified, please click Login and select the Continue with Authenticator option to setup multi-factor authentication.
          </p>
        </header>
        <div className="card-content">
          <div className="columns">
            <div className="column">
              <button onClick={login} className="button is-small">Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
