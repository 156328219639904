import React, { ReactElement } from 'react'
import { AmUserDTO } from '../common/types'
import { formatDate } from '../common/helper'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type RouteParams = {
  user : AmUserDTO
  onClick : (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, user : AmUserDTO) => void
  showAccesses: boolean
  showExtraColumns: boolean
  clickDisabled: boolean
}

const getUserAccessesColumn = (user: AmUserDTO, showAccesses: boolean): ReactElement | null => {
  if (!showAccesses) {
    return null
  } else if (!user.accessContacts || user.accessContacts.length === 0) {
    return (<td>none</td>)
  } else if (user.accessContacts.length === 1) {
    return (<td>{user.accessContacts[0].contactName ?? user.accessContacts[0].contactId}</td>)
  }
  return (
    <td>
      <div className="break-out-from-container tooltip has-tooltip-multiline has-tooltip-text-centered has-tooltip-arrow" data-tooltip={user.accessContacts.map(a => a.contactName ?? a.contactId).join(', ')}>
        ({user.accessContacts.length})
      </div>
    </td>
  )
}

const UserRow = ({ user, onClick, showAccesses, showExtraColumns, clickDisabled } : RouteParams): React.ReactElement => {
  return (
    <tr style={{cursor: "pointer", color: clickDisabled ? 'lightgrey' : 'inherit'}} onClick={e => !clickDisabled && onClick(e, user)}>
      <td className="has-text-weight-bold">{user.name}</td>
      <td>
        {user.email ? user.email : 'N/A'} {!user.emailVerified &&
        <div data-tooltip={"Email unverified"} className="is-inline tooltip has-tooltip-multiline has-tooltip-text-centered has-tooltip-arrow">
          <FontAwesomeIcon
            icon={faExclamationCircle} size={'sm'}
            className="has-text-danger"
            data-tooltip="Email unverified"
          />
        </div>
      }
      </td>
      <td>{user.phoneNumber ? user.phoneNumber : 'N/A'}</td>
      {getUserAccessesColumn(user, showAccesses)}
      <td className={user.lastIp && "tooltip has-tooltip-arrow"} data-tooltip={user.lastIp}>{formatDate(user.lastLogin) || 'never'}</td>
      <td>{user.loginsCount ? user.loginsCount : '0'}</td>
      <td>{formatDate(user.lastUpdate) || 'never'}</td>
      { showExtraColumns &&
        <>
          <td>{formatDate(user.created)}</td>
          <td>{user.id}</td>
          <td>{user.title}</td>
        </>
      }
      <td>{user.accountType}</td>
      <td>{user.tenantId && user.tenantId}</td>
      <td>{user.status}</td>
    </tr>
  )
}

export default UserRow
