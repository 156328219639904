import React, { useContext, useEffect, useState } from 'react'
import * as R from 'ramda'
import { faAdjust, faBan, faCircle, faCrown, faFolder, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPortfolios } from '../../common/api'
import { DispatchContext, StateContext } from '../../common/context'
import { AccessContact, Customer, LookAndFeelId, Portfolio, ReportingDirectoryField, TenantSelect } from '../../common/types'
import { addNotification, setSelectedUser } from '../../common/actions'
import {
  getDefaultLookAndFeelId,
  getDefaultReportingDirectory,
  handleErrorMessage,
  processCustomerFilterInput,
  shouldUseDefaultReportingDirectory
} from '../../common/helper'

enum AccessType {
  NONE = 'none',
  FULL = 'full',
  PARTIAL = 'partial',
  ADMIN = 'admin'
}

const initialAccessContact: AccessContact = { admin: false, viewer: false, whitelist: null, contactId: undefined, ssrsReportId: undefined, reportDirectory: undefined, customReportDirectory: undefined, portfolioAnalyticsDirectory: undefined, privateAssetsDirectory: undefined, esgDirectory: undefined, riskAnalysisDirectory: undefined, securityAnalyticsDirectory: undefined, accountingDirectory: undefined, peerGroupDirectory: undefined, otherDirectory: undefined, reportBundleDirectory: undefined}

/*
 * This allows a user to click the icon preceding the customer name to quickly turn on/off Full Access. This was deemed too risky in the end.
 * Can be turned on later if a user requirement arises.
 */
const useQuickFullAccessSelection = false

const CustomerAccess = (props: { isSelectedTab: boolean }): React.ReactElement => {
  const dispatch = useContext(DispatchContext)
  const { token, selectedUser, allCustomers, allTenants, lookAndFeelIds } = React.useContext(StateContext)
  const { superAdmin } = useContext(StateContext)
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([])
  const [selectedCustomersFilterEnabled, setSelectedCustomersFilter] = useState<boolean>(false)
  const [customerNameFilter, setCustomerNameFilter] = useState<string>('')
  const [customer, setCustomer] = useState<Customer|undefined>()
  const [portfolios, setPortfolios] = useState<Portfolio[]>([])
  const [currentAccess, setCurrentAccess] = useState<AccessContact>(initialAccessContact)
  const [currentAccessType, setCurrentAccessType] = useState<AccessType>(AccessType.NONE)
  const [portfoliosLoading, setPortfoliosLoading] = useState<boolean>(false)

  useEffect(() => {
    if (allCustomers) {
      filterCustomers()
    }
    // eslint-disable-next-line
  }, [selectedCustomersFilterEnabled, allCustomers, customerNameFilter])

  const generateMissingCustomerFromAccessContact = (acc: AccessContact): Customer => ({ id: acc.contactId as number, name: (acc.contactName || ('' + acc.contactId)), portfolioIDs: [], contactCode: undefined, contactTypeName: undefined, contactSubTypeName: undefined })

  const customerTextFilter = (customers: Customer[], filterText: string) => {
    const filterFn = processCustomerFilterInput(filterText)
    return filterFn(customers)
  }

  const filterCustomers = (): void => {
    let filteredCustomers = []
    if (selectedCustomersFilterEnabled) {
      // We need to map on the existing access contacts, so we can also list expired contacts that are no longer visible
      filteredCustomers = R.pipe(
        R.map((acc: AccessContact) => allCustomers.find((cust) => cust.id === acc.contactId) || generateMissingCustomerFromAccessContact(acc)),
        R.sort(R.ascend(R.prop('name')))
      )(selectedUser?.accessContacts || [])
      //filteredCustomers = allCustomers.filter(c => selectedUser?.accessContacts.some(v => v.contactId === c.id))
    } else {
      filteredCustomers = allCustomers
    }
    if (customerNameFilter) {
      filteredCustomers = customerTextFilter(filteredCustomers, customerNameFilter)
    }
    setFilteredCustomers(filteredCustomers)
  }

  const customerSelected = (customer : Customer, toggle?: boolean) => {
    let access = selectedUser.accessContacts?.find(v => v.contactId === customer.id)
    if (!access) {
      access = { admin: false, viewer: false, whitelist: null, contactId: customer.id, ssrsReportId: undefined, reportDirectory: undefined, customReportDirectory: undefined, portfolioAnalyticsDirectory: undefined, privateAssetsDirectory: undefined, esgDirectory: undefined, riskAnalysisDirectory: undefined, securityAnalyticsDirectory: undefined, accountingDirectory: undefined, peerGroupDirectory: undefined, otherDirectory: undefined, reportBundleDirectory: undefined }
    }

    // Turn on 'full access' automatically if no special permissions are set. This is a UI convenience.
    if (toggle && !access.admin && (!access.whitelist || access.whitelist.length === 0)) {
      if (access.viewer) {
        onAccessChange(AccessType.NONE, customer)
      } else {
        onAccessChange(AccessType.FULL, customer)
      }
    } else {
      setCurrentAccess(access)
      setAccessType(access)
      if (!access.admin && access.whitelist !== null) {
        updatePortfolios(customer)
      }
    }
    setPortfolios([])
    setCustomer(customer)
  }

  const updatePortfolios = (cstmr : Customer) => {
    if (cstmr) {
      setPortfoliosLoading(true)
      getPortfolios(token, cstmr.id).then(val => {
        setPortfoliosLoading(false)
        setPortfolios(val)
      }).catch(async (err) => {
        console.log('unable to get portfolios', err)
        dispatch(addNotification({ message: await handleErrorMessage(err, `Error while fetching portfolios`), isError: true }))
      })
    }
  }

  const generateAccessFromType = (accessType: string, contactId: number, currentAccess?: AccessContact): AccessContact => {
    if (accessType === AccessType.ADMIN) {
      return {
        ...(currentAccess || initialAccessContact),
        admin: true,
        viewer: true,
        whitelist: null,
        contactId
      }
    } else if (accessType === AccessType.PARTIAL) {
      return {
        ...(currentAccess || initialAccessContact),
        admin: false,
        viewer: true,
        whitelist: [],
        contactId
      }
    } else if (accessType === AccessType.FULL) {
      return {
        ...(currentAccess || initialAccessContact),
        admin: false,
        viewer: true,
        whitelist: null,
        contactId
      }
    }
    return {
      ...initialAccessContact,
      contactId
    }
  }

  const onAccessChange = (accessType: string, customer: Customer, currentAccess?: AccessContact) => {
    if (accessType === AccessType.PARTIAL) {
      updatePortfolios(customer)
    }

    const newAccess = generateAccessFromType(accessType, customer.id, currentAccess)
    setCurrentAccess(newAccess)
    setAccessType(newAccess)

    const newAccesses = [ ...selectedUser.accessContacts?.filter((a) => a.contactId !== customer.id), newAccess ]
    dispatch(setSelectedUser({ ...selectedUser, accessContacts: newAccesses }))
  }

  const setAccessType = (access : AccessContact) => {
    if (access.admin) {
      setCurrentAccessType(AccessType.ADMIN)
      return
    }

    if (access.viewer && access.whitelist === null) {
      setCurrentAccessType(AccessType.FULL)
      return
    }

    if (access.viewer && access.whitelist !== null) {
      setCurrentAccessType(AccessType.PARTIAL)
      return
    }

    setCurrentAccessType(AccessType.NONE)

  }

  const getAccessTypeName = (a : AccessContact) : AccessType => {
    if (a.admin) {
      return AccessType.ADMIN
    }

    if (a.viewer && a.whitelist === null) {
      return AccessType.FULL
    }

    if (a.viewer && a.whitelist !== null) {
      return AccessType.PARTIAL
    }

    return AccessType.NONE
  }

  const getIcon = (cid : number) => {
    const item = selectedUser.accessContacts?.find(v => v.contactId === cid)
    let accessTypeName = AccessType.NONE

    if (item) {
      accessTypeName = getAccessTypeName(item)
    }
    switch (accessTypeName) {
      case AccessType.ADMIN:
        return (<FontAwesomeIcon icon={faCrown} />)
      case AccessType.FULL:
        return (<FontAwesomeIcon icon={faCircle} />)
      case AccessType.PARTIAL:
        return (<FontAwesomeIcon icon={faAdjust} />)
      default:
        return (<FontAwesomeIcon icon={faBan} />)
    }
  }

  const onWhitelistChange = ( e: React.MouseEvent<HTMLInputElement, MouseEvent>, portfolioId: string) => {
    const newAccess = {
      ...currentAccess
    }
    if (!newAccess.whitelist) {
      newAccess.whitelist = []
    }

    if (newAccess.whitelist.includes(portfolioId)) {
      newAccess.whitelist.splice(newAccess.whitelist.indexOf(portfolioId), 1)
    } else {
      newAccess.whitelist.push(portfolioId)
    }

    setCurrentAccess(newAccess)
    const newAccesses = [ ...selectedUser.accessContacts?.filter((a) => a.contactId !== currentAccess.contactId), newAccess ]
    dispatch(setSelectedUser({ ...selectedUser, accessContacts: newAccesses }))
  }

  const onReportingDirectoryChange = (field: ReportingDirectoryField, value: string) => {
    const newAccess = {
      ...currentAccess,
      [field]: value
    }

    setCurrentAccess(newAccess)
    const newAccesses = [ ...selectedUser.accessContacts?.filter((a) => a.contactId !== currentAccess.contactId), newAccess ]
    dispatch(setSelectedUser({ ...selectedUser, accessContacts: newAccesses }))
  }

  const onLookAndFeelChange = (lookAndFeelId: string) => {
    const newAccess: AccessContact = {
      ...currentAccess,
      ssrsReportId: lookAndFeelId
    }

    setCurrentAccess(newAccess)
    const newAccesses = [ ...selectedUser.accessContacts?.filter((a) => a.contactId !== currentAccess.contactId), newAccess ]
    dispatch(setSelectedUser({ ...selectedUser, accessContacts: newAccesses }))
  }

  const PortfoliosComponent = (props: { currentAccessType: AccessType, currentAccess: AccessContact, portfolios: Portfolio[], onWhitelistChange: (e: React.MouseEvent<HTMLInputElement, MouseEvent>, portfolioID: string) => void }) => {
    if (props.currentAccessType !== AccessType.PARTIAL) {
      return <></>
    }

    if (props.currentAccess.whitelist === null) {
      currentAccess.whitelist = []
    }

    if (props.portfolios.length === 0) {
      return (<i>no portfolios</i>)
    }

    return(
      <>
        {props.portfolios.map(c => <div key={c.id}><label key={c.id} className="checkbox"><input type="checkbox" onClick={e => props.onWhitelistChange(e, c.id)} defaultChecked={props.currentAccess.whitelist?.includes(c.id)} /> {c.name}</label></div>)}
      </>
    )
  }

  const CustomerAccessComponent = (props: { customer: Customer, currentAccessType: AccessType, currentAccess: AccessContact, onAccessChange: (accessType: string, customer: Customer, currentAccess?: AccessContact) => void, portfolios: Portfolio[], onWhitelistChange: (e: React.MouseEvent<HTMLInputElement, MouseEvent>, portfolioID: string) => void }): JSX.Element => (
    <div>
      <h1 className="has-text-grey" style={{fontSize: "0.75em", letterSpacing: "0.1em", textTransform: "uppercase"}}>Access to {props.customer?.name}</h1>

      <div className="field">
        <div className="control has-icons-left">
          <div className="select">
            <select onChange={(e) => props.onAccessChange(e.target.value, props.customer, props.currentAccess)} value={props.currentAccessType}>
              <option value={AccessType.NONE}>No Access</option>
              <option value={AccessType.PARTIAL}>Partial Access</option>
              <option value={AccessType.FULL}>Full Access</option>
              <option value={AccessType.ADMIN}>Admin Access</option>
            </select>
          </div>
          <div className="icon is-small is-left">
            {getIcon(props.customer.id)}
          </div>
        </div>
      </div>
      {portfoliosLoading ? 'loading...' : <PortfoliosComponent currentAccessType={props.currentAccessType} currentAccess={props.currentAccess} portfolios={props.portfolios} onWhitelistChange={props.onWhitelistChange} />}
    </div>
  )

  const LookAndFeelComponent = (props: { currentAccess: AccessContact, onLookAndFeelChange: (lookAndFeelId: string) => void, tenants: TenantSelect[], lookAndFeelIds: LookAndFeelId[] }): JSX.Element => {
    const tenantDefault = getDefaultLookAndFeelId(allTenants, selectedUser)

    return (
      <div>
        <h1 className="has-text-grey" style={{fontSize: "0.75em", letterSpacing: "0.1em", textTransform: "uppercase", marginTop: '1rem'}}>Report look and feel id</h1>

        <div className="field">
          <div className="control is-flex is-align-items-center">
            <div className="select">
              <select onChange={(e) => props.onLookAndFeelChange(e.target.value)} value={props.currentAccess.ssrsReportId}>
                <option value={''}>{tenantDefault ? `Use tenant default ('${tenantDefault}')` : "No default on tenant, use report value"}</option>
                {
                  lookAndFeelIds.map(id => <option key={id} value={id}>{id}</option>)
                }
              </select>
            </div>
              <div style={{ zIndex: 50 }} className="ml-2 icon is-small tooltip has-tooltip-multiline has-tooltip-text-centered has-tooltip-arrow has-tooltip-left"
                data-tooltip="Report look and feel id affects the styling of the reports e.g. colors and logos. Tenant's value is used by default, but you can override the setting on customer basis. If tenant has no default setting, the look and feels is selected by the report itself.">
                <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} className="has-text-grey-lighter" />
              </div>
          </div>
        </div>
      </div>
    )
  }

  const CustomerFilterComponent = (props: { selectedCustomersFilterEnabled: boolean, setCustomerNameFilter: (s: string) => void, setSelectedCustomersFilter: (v: boolean) => void, isSelectedTab: boolean }): JSX.Element => {
    const ref: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (props.isSelectedTab) ref.current?.focus()
    }, [props.isSelectedTab, ref])

    return (
      <>
        <div>
          <div className="field">
            <div className="control is-flex is-align-items-center">
              <input ref={ref} className="input is-small" type="text" placeholder="filter" onChange={e => props.setCustomerNameFilter(e.target.value)} />
              <div style={{height: '100%', marginLeft: '10px', zIndex: 99}} className="icon is-small is-pulled-right tooltip has-tooltip-multiline has-tooltip-text-centered has-tooltip-arrow has-tooltip-right"
                   data-tooltip="You can search by customer name in free text. You can also use the prefixes 'type:' and 'subtype:' to search using the name of customer type/subtypes." >
                <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} className="has-text-grey-lighter" />
              </div>
            </div>
          </div>
        </div>
        <div className="tabs">
          <ul>
            <li className={!props.selectedCustomersFilterEnabled ? 'is-active': ''}><a href='#' onClick={() => props.setSelectedCustomersFilter(false)}>All</a></li>
            <li> | </li>
            <li className={props.selectedCustomersFilterEnabled ? 'is-active': ''}><a href='#' onClick={() => props.setSelectedCustomersFilter(true)}>Selected</a></li>
          </ul>
        </div>
      </>
    )
  }

  const DefaultDirectoryNote = ({ currentAccess, directoryField }: { currentAccess: AccessContact, directoryField: ReportingDirectoryField }) => {
    const defaultDirectory = getDefaultReportingDirectory(allTenants, selectedUser, directoryField)
    const prefix = shouldUseDefaultReportingDirectory(selectedUser, currentAccess, directoryField) ? 'Based on tenant the field will be set to:' : 'Tenant default (will not be set):'
    return <div style={{
      marginBottom: '0px',
      fontStyle: 'italic',
      fontSize: '14px'
    }}>{prefix} "{defaultDirectory || ''}"</div>
  }

  const CustomerDirectoriesComponent = (props: { customer: Customer, currentAccess: AccessContact, onValueChange: (field: ReportingDirectoryField, value: string) => void }): JSX.Element => (
    <div>
      <h1 className="has-text-grey" style={{fontSize: "0.75em", letterSpacing: "0.1em", textTransform: "uppercase", marginTop: '1rem'}}>Reporting directories for {props.customer?.name}</h1>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Reports directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.reportDirectory || ''} onChange={(e) => props.onValueChange('reportDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'reportDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Private assets directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.privateAssetsDirectory || ''} onChange={(e) => props.onValueChange('privateAssetsDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'privateAssetsDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>ESG reports directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.esgDirectory || ''} onChange={(e) => props.onValueChange('esgDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'esgDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Risk analysis directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.riskAnalysisDirectory || ''} onChange={(e) => props.onValueChange('riskAnalysisDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'riskAnalysisDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Portfolio analytics directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.portfolioAnalyticsDirectory || ''} onChange={(e) => props.onValueChange('portfolioAnalyticsDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'portfolioAnalyticsDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Security analytics directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.securityAnalyticsDirectory || ''} onChange={(e) => props.onValueChange('securityAnalyticsDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'securityAnalyticsDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Accounting directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.accountingDirectory || ''} onChange={(e) => props.onValueChange('accountingDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'accountingDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Peer group directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.peerGroupDirectory || ''} onChange={(e) => props.onValueChange('peerGroupDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'peerGroupDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Own reports directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.customReportDirectory || ''} onChange={(e) => props.onValueChange('customReportDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'customReportDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Others directory</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.otherDirectory || ''} onChange={(e) => props.onValueChange('otherDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'otherDirectory'} />
      </div>

      <div className="field">
        <label className="label is-small has-text-grey" style={{ marginRight: '0.5rem', marginTop: '0.5rem' }}>Report bundle share folder</label>
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Use tenant default" value={currentAccess.reportBundleDirectory || ''} onChange={(e) => props.onValueChange('reportBundleDirectory', e.target.value)} />
          <div className="icon is-small is-left">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </div>
        <DefaultDirectoryNote currentAccess={currentAccess} directoryField={'reportBundleDirectory'} />
      </div>

    </div>
  )

  const customerOnClick = (e: React.MouseEvent, customer: Customer, toggle?: boolean): void => {
    e.preventDefault()
    e.stopPropagation()
    customerSelected(customer, toggle)
  }

  return (
    <div className="columns">
      <div className="column is-two-fifths" style={{paddingBottom: 0}}>
        <h1 className="contact-list-header">Customers</h1>
        <div>
          {/* If we call this as a regular component, it will rerender on input change, lose focus and lose its value */}
          {CustomerFilterComponent({ selectedCustomersFilterEnabled, setCustomerNameFilter, setSelectedCustomersFilter, isSelectedTab: props.isSelectedTab })}
        </div>
        <div className="contact-list-scroll-box">
          <aside className="menu">
            <ul className="menu-list">
              {filteredCustomers.map(c =>
                <li key={c.id}>
                  <a href="#" className={c.id === customer?.id ? 'is-active' : ''} onClick={(e) => customerOnClick(e, c)}>
                    <span onClick={(e) => customerOnClick(e, c, useQuickFullAccessSelection)}>
                      {getIcon(c.id)}
                    </span> {c.name}
                  </a></li>
              )}
            </ul>
          </aside>
        </div>
      </div>

      <div className="column contact-list-details-scroll-box" style={customer ? {} : {display: 'none'}}>
        {customer &&
          <>
            <CustomerAccessComponent customer={customer} currentAccessType={currentAccessType} currentAccess={currentAccess} onAccessChange={onAccessChange} portfolios={portfolios} onWhitelistChange={onWhitelistChange} />
            <LookAndFeelComponent currentAccess={currentAccess} onLookAndFeelChange={onLookAndFeelChange} tenants={allTenants} lookAndFeelIds={lookAndFeelIds} />
            {superAdmin &&
              /* If we call this as a regular component, it will rerender on input change, lose focus and lose its value */
              CustomerDirectoriesComponent({ customer: customer, currentAccess: currentAccess, onValueChange: onReportingDirectoryChange })
            }
          </>
        }
      </div>

    </div>
  )
}

export default CustomerAccess
