import React from 'react'

type Props = {
    title : string
    show : boolean
    loading : boolean
    children: React.ReactElement
    extraButtons?: React.ReactElement
    onClose : (save : boolean) => void
}

const Modal = (props : Props) => {
  if (!props.show) {
      return (<div />)
  }

  const onkeyup = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 27) {
      e.stopPropagation()
      props.onClose(false)
    }
  }

  return (
  <div className="modal is-active" onKeyUp={onkeyup} style={{ zIndex: 99 }}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{props.title}</p>
          <button className="delete" aria-label="close" onClick={(ev) => props.onClose(false)}></button>
        </header>
        <section className="modal-card-body" style={{paddingBottom: 0, paddingTop: 0}}>
          {props.loading ? (
            <progress style={{ marginTop: '200px' }} className="progress is-small is-primary" max="100">loading...</progress>
          ) : (
            props.children
          )}
        </section>
        <footer className="modal-card-foot">
              <button className={'button is-success' + (props.loading ? ' is-loading' : '')} disabled={props.loading} onClick={(ev) => props.onClose(true)}>Save</button>
              <button className={'button'} disabled={props.loading} onClick={(ev) => props.onClose(false)}>Cancel</button>
              {props.extraButtons}
        </footer>
      </div>
  </div>)
}

export default Modal
