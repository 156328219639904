import React, { useContext, useEffect, useState } from 'react'
import * as api from '../../common/api'
import UserDetails from './UserDetails'
import { DispatchContext, StateContext } from '../../common/context'
import { addNotification, setSelectedUser } from '../../common/actions'
import { canInviteUser, canSendPin, fillDefaultReportingDirectories, handleErrorMessage } from '../../common/helper'
import Modal from '../../Components/Modal'
import UserAccesses from './UserAccesses'
import UserRoles from './UserRoles'
import { AmUserDTO, newUser } from '../../common/types'

type RouteParams = {
  userId: string
  isVisible: boolean
  closeModal: (refreshUsers?: boolean) => void
}

type Tab = 'details' | 'access' | 'roles'

const UserModal = (props : RouteParams): React.ReactElement => {
  const dispatch = useContext(DispatchContext)
  const [selectedTab, setSelectedTab] = useState<Tab>('details')
  const [isLoading, setLoading] = useState<boolean>(false)
  const { token, superAdmin, selectedUser, userErrors, allTenants } = React.useContext(StateContext)

  const changeTab = (tab : Tab) => {
    setSelectedTab(tab)
  }

  const loadUser = () => {
    if (token && props.userId?.length) {
      setLoading(true)
      api.getUser(token, props.userId).then(usr => {
        dispatch(setSelectedUser({
          ...usr,
          // Store persisted contacts, so we can determine default directories later on
          originalAccessContacts: usr.accessContacts,
          originalName: usr.name
        }))
      }).catch(async (err) => {
        console.log('unable to load user', err)
        dispatch(addNotification({ message: await handleErrorMessage(err, `Error while fetching user`), isError: true }))
      }).finally(() =>
        setLoading(false)
      )
    } else {
      setLoading(false)
    }
  }

  const saveUser = async () => {
    setLoading(true)
    console.log('before default dirs', selectedUser)
    // FILL DEFAULT REPORTING DIRECTORIES FOR CONTACTS
    const userWithDefaultDirs:AmUserDTO = fillDefaultReportingDirectories(allTenants, selectedUser)
    console.log('after default dirs', userWithDefaultDirs)
    if (selectedUser.id?.length) {
      try {
        await api.saveUser(token, selectedUser.id, userWithDefaultDirs)
        console.log(`saved user`)
        dispatch(addNotification({ message: 'User edited successfully' }))
        setLoading(false)
        changeTab('details')
        props.closeModal(true)
      } catch (err) {
        console.log(`couldn't save user`, err)
        dispatch(addNotification({ message: await handleErrorMessage(err, `Error while editing user`), isError: true }))
        setLoading(false)
      }
    } else {
      try {
        await api.createUser(token, userWithDefaultDirs)
        console.log(`created user`)
        dispatch(addNotification({ message: userWithDefaultDirs.shouldSendInvitation ? 'User created and invited successfully' : 'User created, but not invited successfully' }))
        setLoading(false)
        changeTab('details')
        props.closeModal(true)
      } catch (err) {
        console.log(`couldn't create user`, err)
        dispatch(addNotification({ message: await handleErrorMessage(err, `Error while creating user`), isError: true }))
        setLoading(false)
      }
    }
  }

  const deleteUser = async () => {
    setLoading(true)
    if (selectedUser.id?.length) {
      try {
        await api.deleteUser(token, selectedUser.id)
        console.log(`deleted user`)
        dispatch(addNotification({ message: 'User deleted successfully' }))
        setLoading(false)
        changeTab('details')
        dispatch(setSelectedUser(newUser()))
        props.closeModal(true)
      } catch (err) {
        console.log(`couldn't delete user`, err)
        dispatch(addNotification({ message: await handleErrorMessage(err, `Error while deleting user`), isError: true }))
        setLoading(false)
      }
    } else {
      console.log(`couldn't delete user`)
      dispatch(addNotification({ message: `No id for user to delete`, isError: true }))
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log('UserAccessEdit useEffect...')
    loadUser()
  }, [dispatch, props.userId, token])

  useEffect(() => {
    console.log('USER REFRESHED')
  }, [selectedUser])

  const resendInvitation = (userId: string) => {
    if (token) {
      if (!selectedUser?.invitationApplicationName) {
        dispatch(addNotification({ message: 'Error while sending invitation! Fill in the field "Application to redirect to from invitation" and try again.', isError: true }))
        return
      }
      setLoading(true)
      api.sendInvitation(token, userId, selectedUser).then(user => {
        props.closeModal(true)
        dispatch(addNotification({message: `Invitation sent successfully`}))
        setLoading(false)
      }).catch(async (err) => {
        setLoading(false)
        dispatch(addNotification({ message: await handleErrorMessage(err, `Error while sending invitation`), isError: true }))
      })
    }
  }

  const sendPinCode = (userId: string) => {
    if (token) {
      setLoading(true)
      api.sendPinCode(token, userId).then(user => {
        props.closeModal()
        dispatch(addNotification({message: `New pin code sent successfully`}))
        setLoading(false)
      }).catch(async (err) => {
        setLoading(false)
        dispatch(addNotification({ message: await handleErrorMessage(err, `Error while sending new pin code`), isError: true }))
      })
    }
  }

  const extrabuttons = (): React.ReactElement | undefined => {
    if (selectedUser && selectedUser.id?.length) {
      return (
        <>
          {canInviteUser(selectedUser) ? (
            <button className="button" disabled={isLoading} onClick={(ev) => { resendInvitation(selectedUser.id) }}>Send new invitation and pin</button>
          ) : (
            <button disabled={true} className="button"><span className="active tooltip has-tooltip-arrow has-tooltip-top has-tooltip-multiline has-tooltip-text-centered" data-tooltip="Re-sending invitation is only available when the user does not have both Strong and TOTP and has a phone number">Re-send invitation</span></button>
          )}
          {canSendPin(selectedUser) ? (
            <button className="button" disabled={isLoading} onClick={(ev) => { sendPinCode(selectedUser.id) }}>Send new pin</button>
          ) : (
            <button disabled={true} className="button"><span className="active tooltip has-tooltip-arrow has-tooltip-top has-tooltip-multiline has-tooltip-text-centered" data-tooltip="Re-sending pin is only available when the user already has a pin">Re-send pin</span></button>
          )}
        </>
      )
    }
  }

  const onCloseModal = async (save: boolean) => {
    if (!save) {
      changeTab('details')
      props.closeModal(false)
    } else {
      if (userErrors.length > 0) {
        console.log('errors', userErrors)
        dispatch(addNotification({ message: `There are errors in the following fields: ${userErrors.join(', ')}`, isError: true }))
        return
      }
      await saveUser()
    }
  }

  return (
    <Modal loading={isLoading} show={props.isVisible} title={!isLoading && selectedUser.originalName ? selectedUser.originalName :  'User Access Editor'} onClose={onCloseModal} extraButtons={extrabuttons()}>
      <div className="userAccessEdit" style={{paddingBottom: "20px"}}>
        <div className="tabs">
          <ul>
            <li onClick={(e) => {e.preventDefault(); changeTab('details')}} className={selectedTab === 'details' ? 'is-active' : ''}><a href="#">Details</a></li>
            {superAdmin && <li onClick={(e) => {e.preventDefault(); changeTab('access')}} className={selectedTab === 'access' ? 'is-active' : ''}><a href="#">Access</a></li>}
            {superAdmin && <li onClick={(e) => {e.preventDefault(); changeTab('roles')}} className={selectedTab === 'roles' ? 'is-active' : ''}><a href="#">Roles</a></li>}
          </ul>
        </div>

        <div className="userDetails" style={selectedTab !== 'details' ? {display: 'none'} : {}}>
          <UserDetails isSelectedTab={selectedTab === 'details'} deleteUser={deleteUser} />
        </div>
        {superAdmin && (
          <div className="userAccess" style={selectedTab !== 'access' ? {display: 'none'} : {}}>
            <UserAccesses isSelectedTab={selectedTab === 'access'} />
          </div>
        )}
        {superAdmin && (
          <div className="userRoles" style={selectedTab !== 'roles' ? { display: 'none' } : {}}>
            <UserRoles isSelectedTab={selectedTab === 'roles'} />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default UserModal
