import { AmUserDTO, TableSortDirection, TableSorting } from '../common/types'
import * as R from 'ramda'
import { formatDate } from '../common/helper'
import React, { useContext, useState } from 'react'
import UserRow from './UserRow'
import { onUserClickedParams, SortByParams } from '../Containers/access'
import { setUsers } from '../common/actions'
import { DispatchContext, StateContext } from '../common/context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleExclamation, faDownload, faTableColumns } from '@fortawesome/free-solid-svg-icons'
import * as XLSX from 'xlsx-js-style'

interface UsersTableParams {
  onUserClicked: onUserClickedParams
  clickDisabled: boolean
  filterInput: string | undefined
}

const downloadTableAsExcel = () => {
  const rows = R.pipe<Document[], NodeListOf<Element>, Element[], any[][]>(
    (doc) => doc.querySelectorAll('table#user-table tr'),
    Array.from,
    R.map(
      R.pipe<Element[], NodeListOf<Element>, Element[], any[]>(
        (rowNode) => rowNode.querySelectorAll('td, th'),
        Array.from,
        R.map(
          (cellNode: Element) => ({
            v: cellNode.textContent,
            t: 's',
            s: { font: { bold: cellNode.tagName === 'TH', sz: 12 } }
          })
        )
      )
    )
  )(document)
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(rows)
  XLSX.utils.book_append_sheet(wb, ws)
  XLSX.writeFile(wb, "data-table-export.xlsx");
}

const MemoizedRow = React.memo(UserRow)

const UsersTable = ({ onUserClicked, clickDisabled, filterInput }: UsersTableParams) => {
  const { superAdmin, users, globalLoading } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const [sort, setSort] = useState<TableSorting | undefined>(undefined)
  const [showExtraColumns, setShowExtraColumns] = useState<boolean>(false)

  const sortBy: SortByParams = (tableSorting: TableSorting, formatFn: (s: string | undefined) => string = ((s) => s ?? '')) => () => {
    if (users) {
      const direction = tableSorting.field === sort?.field ? sort.direction * -1 : tableSorting.direction
      const orderBy = R.equals(direction, TableSortDirection.ASC) ? R.ascend : R.descend
      const path = tableSorting.field.includes('.') ? tableSorting.field.split('.') : [tableSorting.field]
      const nullSafer = (s: string | undefined): string => s ?? ''

      dispatch(setUsers(
        R.sortWith<AmUserDTO>([
          orderBy(
            R.pipe(
              R.path(path),
              nullSafer,
              formatFn
            )
          )
        ])(users)
      ))
      setSort({
        ...tableSorting,
        direction
      })
    }
  }

  if (filterInput === undefined) {
    if (!globalLoading) {
      return (
        <div className="has-text-centered">
          <FontAwesomeIcon
            icon={faCheckCircle} size={'2xl'}
            className="has-text-info mt-3"
            data-tooltip="No access, please login"
            style={{ cursor: 'default' }}
          />
          <h1 className="table-container is-fullwidth has-text-centered heading is-size-6 mt-1 mb-6">
            Ready
          </h1>
        </div>
      )
    } else {
      return <></>
    }
  }

  if (!globalLoading && !users?.length) {
    return (
      <div className="has-text-centered">
        <FontAwesomeIcon
          icon={faCircleExclamation} size={'2xl'}
          className="has-text-info mt-3"
          data-tooltip="No access, please login"
          style={{ cursor: 'default' }}
        />
        <h1 className="table-container is-fullwidth has-text-centered heading is-size-6 mt-1 mb-6">
          No users found with query: {filterInput}
        </h1>
      </div>
    )
  }

  if (!users?.length) {
    return <></>
  }

  return (
    <>
      <div className="field">
        <h1 className="title is-5">
          Users
          {' '}<span className="icon tooltip has-tooltip-arrow has-tooltip-right has-text-weight-normal has-text-de" style={{ cursor: 'pointer', verticalAlign: 'text-bottom' }} data-tooltip="Download the table in Excel format">
              <FontAwesomeIcon size={'sm'} icon={faDownload} onClick={downloadTableAsExcel} />
              </span>
          {' '}<span className="icon tooltip has-tooltip-arrow has-tooltip-right has-text-weight-normal" style={{ cursor: 'pointer', verticalAlign: 'text-bottom' }} data-tooltip="Show/hide extra columns: created date">
              <FontAwesomeIcon size={'sm'} icon={faTableColumns} onClick={() => setShowExtraColumns(!showExtraColumns) } />
              </span>
        </h1>
      </div>
      <div className="table-container">
        <table className="table is-fullwidth is-hoverable" id="user-table">
          <thead>
          <tr>
            <th onClick={sortBy({ field: 'name', direction: TableSortDirection.ASC }, R.toLower)} style={{ cursor: 'pointer' }}>Full Name</th>
            <th onClick={sortBy({ field: 'email', direction: TableSortDirection.ASC }, R.toLower)} style={{ cursor: 'pointer' }}>Email</th>
            <th>Phone</th>
            {superAdmin && <th>Accesses</th>}
            <th onClick={sortBy({ field: 'lastLogin', direction: TableSortDirection.DESC }, formatDate)} style={{ cursor: 'pointer' }}>Last Login</th>
            <th onClick={sortBy({ field: 'loginsCount', direction: TableSortDirection.DESC })} style={{ cursor: 'pointer' }}>Login Count</th>
            <th onClick={sortBy({ field: 'lastUpdate', direction: TableSortDirection.DESC }, formatDate)} style={{ cursor: 'pointer' }}>
              <span className="tooltip has-tooltip-arrow has-tooltip-bottom has-tooltip-multiline has-tooltip-text-centered" data-tooltip="When a user logs in, some data is edited each time. Therefore at this time this fields also includes logins.">Last update or login</span>
            </th>
            { showExtraColumns &&
              <>
                <th onClick={sortBy({ field: 'created', direction: TableSortDirection.DESC }, formatDate)} style={{ cursor: 'pointer' }}>Created</th>
                <th onClick={sortBy({ field: 'id', direction: TableSortDirection.ASC }, R.toLower)} style={{ cursor: 'pointer' }}>User Id</th>
                <th onClick={sortBy({ field: 'title', direction: TableSortDirection.ASC }, formatDate)} style={{ cursor: 'pointer' }}>Title</th>
              </>
            }
            <th>Account Type</th>
            <th onClick={sortBy({ field: 'tenantId', direction: TableSortDirection.DESC })} style={{ cursor: 'pointer' }}>Tenant</th>
            <th onClick={sortBy({ field: 'status', direction: TableSortDirection.DESC })} style={{ cursor: 'pointer' }}>Status</th>
          </tr>
          </thead>
          <tbody>
          {users.map(u => <MemoizedRow key={u.id} user={u} onClick={(e) => onUserClicked(e, u)} showAccesses={superAdmin} showExtraColumns={showExtraColumns} clickDisabled={clickDisabled} />)}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UsersTable
