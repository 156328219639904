import React, { useEffect, useState } from 'react'
import { useAuth0 } from '../common/auth0'
import { StateContext } from '../common/context'

type RouteParams = {
}

interface UserOptionType {
  id: string
  name: string
}

const Header = () => {
  const state = React.useContext(StateContext)
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false)

  const { logout, user } = useAuth0()

  useEffect(() => {
    // eslint-disable-next-line
  }, [state.user])

  const getBtn = () => {
    if (user) {
      return (<button className="button" onClick={() => logout({ federated: true, returnTo: window.location.protocol + '//' + window.location.host + window.location.pathname })}>Log out</button>)
    }
  }

  const getAvatar = () => {
    if (state.user) {
      return (
        <div className="media" style={{ marginRight: "20px" }}>
          <div className="media-left">
            <img src={state.user.picture} alt="Placeholder" style={{ width: "50px", height: "50px", maxHeight: "50px" }} />
          </div>
          <div className="media-content">
            <p className="title is-4">{state.user.name}</p>
            <p className="subtitle is-6">{state.user.email}</p>
          </div>
        </div>
      )
    }
  }

  const onBurgerClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    console.log('onBurgerClick')
    setMobileMenuActive(!mobileMenuActive)
  }

  const LoadingBar = () => <progress style={{ zIndex: 99, opacity: 0.9 }} className="is-overlay progress is-small is-primary" max="100">loading...</progress>

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation" style={{ borderBottom: "1px solid rgb(226, 219, 219)" }}>
      {state.globalLoading && <LoadingBar /> }
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={process.env.PUBLIC_URL + '/favicons/favicon-2-192x192.png'} alt="logo" style={{ maxHeight: "50px" }} />
        </a>

        <a href="#" role="button" className={"navbar-burger burger" + (mobileMenuActive ? " is-active" : "")} aria-label="menu" aria-expanded="false" onClick={onBurgerClick}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navi" className={"navbar-menu" + (mobileMenuActive ? " is-active" : "")}>

        <div className="navbar-start">
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            {getAvatar()}
            <div className="buttons">
              {getBtn()}
            </div>
          </div>
        </div>

      </div>
    </nav>
  )
}

export default Header
