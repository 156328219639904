import React, {ReactElement} from 'react'
import classnames from 'classnames'
import { Notif } from '../common/types'
import { DispatchContext, StateContext } from '../common/context'
import { hideNotification as hideFromContext } from '../common/actions'

interface NotificationProps {
  notification: Notif
  hideNotification: Function
  index: number
}

const Notification = ({ notification, hideNotification, index }: NotificationProps): ReactElement => {
  const bottom = 5 + index * 66
  return (
    <div
      className={classnames('notification', 'notif', 'has-text-weight-bold', notification.isError ? 'is-danger' : 'is-success')}
      style={{ right: `${5}px`, bottom: `${bottom}px` }}
    >
      <button type="button" className="delete" onClick={() => hideNotification(notification)}>
        {!notification.isSticky && setTimeout(() => hideNotification(notification), 5000)}
      </button>
      {notification.message}
    </div>
  )
}

const Notifications = () => {
  const { notifications } = React.useContext(StateContext)
  const dispatch = React.useContext(DispatchContext)

  const hideNotification = (notification: Notif) => dispatch(hideFromContext(notification))

  return (
    <div>
      {notifications
        .filter(n => n.visible)
        .map((n, index) => (
          <Notification notification={n} hideNotification={hideNotification} index={index} key={index} />
        ))}
    </div>
  )
}

export default Notifications
