import * as R from 'ramda'
import { AccessContact, AmUserDTO, Customer, ReportingDirectoryField, TenantSelect } from './types'
import moment from 'moment'

const accessesFieldFilterKey = 'accesses:'
const tenantFieldFilterKey = 'tenant:'
const statusFieldFilterKey = 'status:'
const accountTypeFilterKey = 'account type:'
const userIdFilterKey = 'id:'

const customerSubTypeFilterKey = 'subtype:'
const customerTypeFilterKey = 'type:'
const roleFilterKey = 'role:'

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePhone = (phone?: string): boolean => {
  if (!phone) {
    return false
  }
  let re = /^\+[ 0-9\\+-\\#\\*]{3,30}$/
  return re.test(phone)
}

export const validateTenant = (tenant?: string): boolean => {
  return !!tenant && tenant !== 'empty' && tenant !== ''
}

export const validateApplication = (app?: string, userId?: string): boolean => {
  return !!userId?.length || (!!app && app !== '')
}

export const validateUserFields = (user: AmUserDTO): string[] => {
  let errs: string[] = []

  if (!user) return []

  if (user.name.trim() === '') {
    errs.push('name')
  }

  if (!validateEmail(user.email)) {
    errs.push('email')
  }

  if (!validatePhone(user.phoneNumber)) {
    errs.push('phone_number')
  }

  if (!validateTenant(user.tenantId)) {
    errs.push('tenantname')
  }

  if (!validateApplication(user.invitationApplicationName, user.id)) {
    errs.push('invitationApplication')
  }
  return errs
}

const matchFieldInArray = <T>(arr: any[] | undefined, field: keyof T, searchClause: string): boolean => {
  if (searchClause === '') {
    return !arr || arr.length === 0
  }
  return !!arr?.some(access => matchString(access[field], searchClause))
}

const matchString = (value: string | undefined, searchClause: string): boolean => {
  if (searchClause === '') {
    return !value
  }
  return !!value?.match(new RegExp(searchClause, 'i'))
}

const userAccessesFilter = (input: string): { clause: string, criteriaFn: ((user: AmUserDTO) => boolean) } => {
  const searchClause = input.substr(input.indexOf(accessesFieldFilterKey) + accessesFieldFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(accessesFieldFilterKey)).trim(),
    criteriaFn: (user: AmUserDTO) => matchFieldInArray<AccessContact>(user?.accessContacts, 'contactName', searchClause)
  }
}

const userTenantFilter = (input: string): { clause: string, criteriaFn: ((user: AmUserDTO) => boolean) } => {
  const searchClause = input.substr(input.indexOf(tenantFieldFilterKey) + tenantFieldFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(tenantFieldFilterKey)).trim(),
    criteriaFn: (user: AmUserDTO) => matchString(user?.tenantId, searchClause)
  }
}

const userStatusFilter = (input: string): { clause: string, criteriaFn: ((user: AmUserDTO) => boolean) } => {
  const searchClause = input.substr(input.indexOf(statusFieldFilterKey) + statusFieldFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(statusFieldFilterKey)).trim(),
    criteriaFn: (user: AmUserDTO) => matchString(user?.status, searchClause)
  }
}

const userAccountTypeFilter = (input: string): { clause: string, criteriaFn: ((user: AmUserDTO) => boolean) } => {
  const searchClause = input.substr(input.indexOf(accountTypeFilterKey) + accountTypeFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(accountTypeFilterKey)).trim(),
    criteriaFn: (user: AmUserDTO) => matchString(user?.accountType, searchClause)
  }
}

const userIdFilter = (input: string): { clause: string, criteriaFn: ((user: AmUserDTO) => boolean) } => {
  const searchClause = input.substr(input.indexOf(userIdFilterKey) + userIdFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(userIdFilterKey)).trim(),
    // Escape special characters from regexp like '|'
    criteriaFn: (user: AmUserDTO) => matchString(user?.id, searchClause.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
  }
}

const customerTypeFilter = (input: string): { clause: string, criteriaFn: ((customer: Customer) => boolean) } => {
  const searchClause = input.substr(input.indexOf(customerTypeFilterKey) + customerTypeFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(customerTypeFilterKey)).trim(),
    criteriaFn: (customer: Customer) => matchString(customer?.contactTypeName, searchClause)
  }
}

const customerSubTypeFilter = (input: string): { clause: string, criteriaFn: ((customer: Customer) => boolean) } => {
  const searchClause = input.substr(input.indexOf(customerSubTypeFilterKey) + customerSubTypeFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(customerSubTypeFilterKey)).trim(),
    criteriaFn: (customer: Customer) => matchString(customer?.contactSubTypeName, searchClause)
  }
}

const roleNameFilter = (input: string): { clause: string, roleFilterStr: string } => {
  const searchClause = input.substr(input.indexOf(roleFilterKey) + roleFilterKey.length).trim()
  return {
    clause: input.substr(0, input.indexOf(roleFilterKey)).trim(),
    roleFilterStr: searchClause
  }
}

// We always want to look for substrings in name and email unless we have specific fields in queries
const userWildCardFilter = (searchClause: string): string => `name:*${searchClause}* OR email:*${searchClause}*`

export const processUserFilterInput = (filterInput: string | undefined): { searchClause: string | undefined, postFilterFn: (users: AmUserDTO[]) => AmUserDTO[], roleFilter: string | undefined } => {
  let searchClause = filterInput?.trim()
  let roleFilter = undefined
  let criteriaFns: ((user: AmUserDTO) => boolean)[] = []

  if (searchClause?.includes(accessesFieldFilterKey)) {
    const { clause, criteriaFn } = userAccessesFilter(searchClause)
    criteriaFns = [...criteriaFns, criteriaFn]
    searchClause = clause
  }
  if (searchClause?.includes(tenantFieldFilterKey)) {
    const { clause, criteriaFn } = userTenantFilter(searchClause)
    criteriaFns = [...criteriaFns, criteriaFn]
    searchClause = clause
  }
  if (searchClause?.includes(statusFieldFilterKey)) {
    const { clause, criteriaFn } = userStatusFilter(searchClause)
    criteriaFns = [...criteriaFns, criteriaFn]
    searchClause = clause
  }
  if (searchClause?.includes(accountTypeFilterKey)) {
    const { clause, criteriaFn } = userAccountTypeFilter(searchClause)
    criteriaFns = [...criteriaFns, criteriaFn]
    searchClause = clause
  }
  if (searchClause?.includes(userIdFilterKey)) {
    const { clause, criteriaFn } = userIdFilter(searchClause)
    criteriaFns = [...criteriaFns, criteriaFn]
    searchClause = clause
  }
  if (searchClause?.includes(roleFilterKey)) {
    const { clause, roleFilterStr } = roleNameFilter(searchClause)
    roleFilter = roleFilterStr
    searchClause = clause
  }
  if (searchClause && !searchClause.includes(":")) {
    searchClause = userWildCardFilter(searchClause)
  }
  return {
    searchClause,
    postFilterFn: R.filter(R.allPass(criteriaFns)),
    // roleFilter will be '' if it is present, but empty. Undefined if it is not present.
    roleFilter: roleFilter
  }
}

const customerWildCardFilter = (searchClause: string) => (customer: Customer) => !!customer.name?.toLowerCase().match(`.*${searchClause.toLowerCase()}.*`)

export const processCustomerFilterInput = (filterInput: string | undefined): (customers: Customer[]) => Customer[] => {
  let searchClause = filterInput?.trim()
  let criteriaFns: ((customer: Customer) => boolean)[] = []

  if (searchClause?.includes(customerSubTypeFilterKey)) {
    const { clause, criteriaFn } = customerSubTypeFilter(searchClause)
    criteriaFns = [...criteriaFns, criteriaFn]
    searchClause = clause
  }
  if (searchClause?.includes(customerTypeFilterKey)) {
    const { clause, criteriaFn } = customerTypeFilter(searchClause)
    criteriaFns = [...criteriaFns, criteriaFn]
    searchClause = clause
  }
  if (searchClause) {
    const criteriaFn = customerWildCardFilter(searchClause.trim())
    criteriaFns = [...criteriaFns, criteriaFn]
  }
  return R.filter(R.allPass(criteriaFns))
}

export const formatDate = (dateStr: string | Date | undefined): string => dateStr ? moment(dateStr).local().format() : ''

export const canInviteUser = (user: AmUserDTO) => {
  return (!user.id?.length || user.canReInvite) && !!user.phoneNumber
}

export const canSendPin = (user: AmUserDTO) => {
  return user.canReSendPin && !!user.phoneNumber
}

export const handleErrorMessage = async (err: any, fallbackMessage: string) => {
  const message = err?.response ? await err.response.text() : fallbackMessage
  if (message?.includes('<H1>403 ERROR</H1>')) {
    return 'Not authorized. Make sure you have VPN turned on. You can also try refreshing the page.'
  }
  return message
}

export const shouldUseDefaultReportingDirectory = (user: AmUserDTO, accessContact: AccessContact, directoryField: ReportingDirectoryField) => {
  const fieldIsNotSet = !accessContact[directoryField]
  const isNewAccessContact = !user.originalAccessContacts?.some(a => a.contactId === accessContact.contactId)
  return fieldIsNotSet && isNewAccessContact
}

export const getDefaultReportingDirectory = (tenants: TenantSelect[], user: AmUserDTO, directoryField: ReportingDirectoryField): string | undefined => {
  if (!user?.tenantId) return undefined
  const tenant = tenants.find(t => user.tenantId === t.tenantName)
  if (!tenant) return undefined
  return R.cond([
    [R.equals<ReportingDirectoryField>('reportDirectory'), R.always(tenant.defaultReportDirectory)],
    [R.equals<ReportingDirectoryField>('customReportDirectory'), R.always(tenant.defaultCustomReportDirectory)],
    [R.equals<ReportingDirectoryField>('portfolioAnalyticsDirectory'), R.always(tenant.defaultPortfolioAnalyticsDirectory)],
    [R.equals<ReportingDirectoryField>('privateAssetsDirectory'), R.always(tenant.defaultPrivateAssetsDirectory)],
    [R.equals<ReportingDirectoryField>('esgDirectory'), R.always(tenant.defaultEsgDirectory)],
    [R.equals<ReportingDirectoryField>('riskAnalysisDirectory'), R.always(tenant.defaultRiskAnalysisDirectory)],
    [R.equals<ReportingDirectoryField>('securityAnalyticsDirectory'), R.always(tenant.defaultSecurityAnalyticsDirectory)],
    [R.equals<ReportingDirectoryField>('accountingDirectory'), R.always(tenant.defaultAccountingDirectory)],
    [R.equals<ReportingDirectoryField>('peerGroupDirectory'), R.always(tenant.defaultPeerGroupDirectory)],
    [R.equals<ReportingDirectoryField>('otherDirectory'), R.always(tenant.defaultOtherDirectory)],
    [R.equals<ReportingDirectoryField>('reportBundleDirectory'), R.always(tenant.defaultReportBundleDirectory)],
    [R.T, R.always(undefined)]
  ])(directoryField)
}

export const getDefaultLookAndFeelId = (tenants: TenantSelect[], user: AmUserDTO): string | undefined => {
  if (!user?.tenantId) return undefined
  const tenant = tenants.find(t => user.tenantId === t.tenantName)
  if (!tenant) return undefined
  return tenant.defaultSsrsReportId
}

export const fillDefaultReportingDirectories = (tenants: TenantSelect[], user: AmUserDTO): AmUserDTO => ({
  ...user,
  accessContacts: user.accessContacts.map(a => ({
    ...a,
    reportDirectory: shouldUseDefaultReportingDirectory(user, a, 'reportDirectory') ? getDefaultReportingDirectory(tenants, user, 'reportDirectory') : a.reportDirectory,
    customReportDirectory: shouldUseDefaultReportingDirectory(user, a, 'customReportDirectory') ? getDefaultReportingDirectory(tenants, user, 'customReportDirectory') : a.customReportDirectory,
    portfolioAnalyticsDirectory: shouldUseDefaultReportingDirectory(user, a, 'portfolioAnalyticsDirectory') ? getDefaultReportingDirectory(tenants, user, 'portfolioAnalyticsDirectory') : a.portfolioAnalyticsDirectory,
    privateAssetsDirectory: shouldUseDefaultReportingDirectory(user, a, 'privateAssetsDirectory') ? getDefaultReportingDirectory(tenants, user, 'privateAssetsDirectory') : a.privateAssetsDirectory,
    esgDirectory: shouldUseDefaultReportingDirectory(user, a, 'esgDirectory') ? getDefaultReportingDirectory(tenants, user, 'esgDirectory') : a.esgDirectory,
    riskAnalysisDirectory: shouldUseDefaultReportingDirectory(user, a, 'riskAnalysisDirectory') ? getDefaultReportingDirectory(tenants, user, 'riskAnalysisDirectory') : a.riskAnalysisDirectory,
    securityAnalyticsDirectory: shouldUseDefaultReportingDirectory(user, a, 'securityAnalyticsDirectory') ? getDefaultReportingDirectory(tenants, user, 'securityAnalyticsDirectory') : a.securityAnalyticsDirectory,
    accountingDirectory: shouldUseDefaultReportingDirectory(user, a, 'accountingDirectory') ? getDefaultReportingDirectory(tenants, user, 'accountingDirectory') : a.accountingDirectory,
    peerGroupDirectory: shouldUseDefaultReportingDirectory(user, a, 'peerGroupDirectory') ? getDefaultReportingDirectory(tenants, user, 'peerGroupDirectory') : a.peerGroupDirectory,
    otherDirectory: shouldUseDefaultReportingDirectory(user, a, 'otherDirectory') ? getDefaultReportingDirectory(tenants, user, 'otherDirectory') : a.otherDirectory,
    reportBundleDirectory: shouldUseDefaultReportingDirectory(user, a, 'reportBundleDirectory') ? getDefaultReportingDirectory(tenants, user, 'reportBundleDirectory') : a.reportBundleDirectory
  }))
})

export const capitalize = R.replace(/^./, R.toUpper)
