import React, { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '../../common/auth0'
import { linkAccount } from '../../common/api'
import ErrorPage from '../Error'
import { DispatchContext, StateContext } from '../../common/context'
import { setError } from '../../common/actions'
import { getValues } from '../VerifyAccount'

const deleteVerify = () => {
  localStorage.removeItem('verify')
}

export const StrongFlowStart = (): React.ReactElement => {
  const { error } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { loginWithRedirect, loading } = useAuth0()

  const login = () => {
    console.log('verify')
    dispatch(setError(undefined))
    loginWithRedirect({
      redirect_uri: `${window.location.origin}/verify/strong/callback`,
      connection: 'PankkitunnistusPROD'
    })
  }

  useEffect(() => {
    if (!loading) {
      login()
    }
  }, [loading])

  if (loading) {
    return (<progress className="progress is-small is-primary" max="100">loading...</progress>)
  }

  // Please close this page, and use original link to account verification.
  if (error) {
    return <ErrorPage error={error.title} description={error.description} />
  }


  return (
    <div className="container activate-container">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            Activating strong authentication
          </p>
        </header>
        <div className="card-content">
          <form className="columns">
            <div className="column is-2 is-vcentered">
              <p>Proceeding to Telia...</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export const StrongFlowCallBack = (): React.ReactElement => {
  const { error } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { loading, user, getTokenSilently, logout } = useAuth0()
  const [completed, setCompleted] = useState<boolean>(false)
  const [localLoading, setLocalLoading] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>(undefined)

  const vals = getValues()

  const logOutWhenSuccess = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    const returnTo = url ? url : process.env.REACT_APP_PORTAL_URI ? process.env.REACT_APP_PORTAL_URI : ''
    console.log(`logging out returnTo ${returnTo}`)
    logout({ returnTo: `${returnTo}?auth_method=strong` })
  }

  const link = () => {
    getTokenSilently().then(tok => {
      console.log('getTokenSilently', tok)

      // Link accounts
      if (vals && tok && vals.pin) {
        setLocalLoading(true)
        linkAccount(tok, "", vals.code, vals.pin).then(ret => {
          console.log('linkAccounts', ret)
          setUrl(ret.url)
          setCompleted(true)
          setLocalLoading(false)
        }).catch(async (err) => {
          console.log('linkAccounts error', err)
          const errorMessage = 'Account verification failed'
          const errorDescription = err.response ? await err.response.text() + '. Please contact support.' : 'Please contact support'
          dispatch(setError({ title: errorMessage,  description: errorDescription }));
          setLocalLoading(false)
        }).finally(() => {
          deleteVerify()
        })
      }

    }).catch(err => {
      console.log('getTokenSilently error', err)
    })
    //console.log('user is already logged in, logging out...')
    //logout({returnTo: window.location.href})
  }

  useEffect(() => {
    console.log('user', user)
    if (user) {
      // console.log('Should link')
      link()
    }
    // eslint-disable-next-line
  }, [user])

  console.log('localloading', localLoading)
  if (loading || localLoading) {
    return (<progress className="progress is-small is-primary" max="100">loading...</progress>)
  }

  // Please close this page, and use original link to account verification.
  if (error) {
    return <ErrorPage error={error.title} description={error.description} />
  }

  if (completed) {
    return (<div className="container activate-container">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            <img src={process.env.PUBLIC_URL + '/favicons/favicon-2-32x32.png'} alt="logo" width="32" height="32" style={{ marginRight: '10px' }} />
            Account is now verified, please click Login to continue
          </p>
        </header>
        <div className="card-content">
          <div className="columns">
            <div className="column">
              <button onClick={logOutWhenSuccess} className="button is-small">Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>)

  } else {
    return (
      <div className="container activate-container">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              Activating strong authentication
            </p>
          </header>
          <div className="card-content">
            <form className="columns">
              <div className="column is-2 is-vcentered">

              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

}
