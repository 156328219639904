import React from 'react'

type RouteParams = {
    error : string
    description? : string | string[] | null | undefined
}

interface UserOptionType {
    id: string
    name: string
}

// React.FunctionComponent<RouteParams> should have been enough, but instead needed to use PropsWithChildren
const ErrorPage: React.FunctionComponent<RouteParams> = (props : React.PropsWithChildren<RouteParams>) => (
    <div className="container activate-container">
      <div className="card is-danger">
        <header className="card-header">
          <p className="card-header-title">
            <img src={process.env.PUBLIC_URL + '/favicons/favicon-2-32x32.png'} alt="logo" width="32" height="32" style={{marginRight: '10px'}} />
            {props.error}
          </p>
        </header>
        <div className="card-content">
          <div className="columns">
            <div className="column">
              {props.children ? (
                <>{props.children}</>
              ) : (
                <p>{props.description}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
)

export default ErrorPage
