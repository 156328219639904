import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Role } from '../../common/types'
import { DispatchContext, StateContext } from '../../common/context'
import { setSelectedUser } from '../../common/actions'

const UserRoles = (props: { isSelectedTab: boolean }): React.ReactElement => {
  const dispatch = useContext(DispatchContext)
  const { selectedUser, allRoles } = useContext(StateContext)
  const [selectedRolesFilterEnabled, setSelectedRolesFilter] = useState<boolean>(false)
  const [roleNameFilter, setRoleNameFilter] = useState<string>('')
  const [filteredRoles, setFilteredRoles] = useState<Role[]>([])

  const ref: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props.isSelectedTab) ref.current?.focus()
  }, [props.isSelectedTab, ref])

  useEffect(() => {
    if (allRoles?.length && selectedUser) {
      filterRoles()
    }
    // eslint-disable-next-line
  }, [selectedRolesFilterEnabled, allRoles, selectedUser, roleNameFilter])

  const isRoleSelected = useCallback((id: string) => {
    return !!selectedUser.roles?.some((role) => role.id === id)
  }, [selectedUser.roles])

  const filterRoles = (): void => {
    let filteredRoles = []
    if (selectedRolesFilterEnabled) {
      // We need to map on the existing access contacts, so we can also list expired contacts that are no longer visible
      filteredRoles = selectedUser.roles || []
    } else {
      filteredRoles = allRoles
    }
    if (roleNameFilter) {
      filteredRoles = filteredRoles.filter(role => !!role.name.toLowerCase().match(`.*${roleNameFilter.toLowerCase()}.*`))
    }
    setFilteredRoles(filteredRoles)
  }

  const addRole = (selectedRoleId: string) => {
    if (selectedRoleId) {
      const newRole = allRoles.find(r => r.id === selectedRoleId) as Role
      const newRoles: Role[] = [...(selectedUser.roles || []), newRole]
      dispatch(setSelectedUser({ ...selectedUser, roles: newRoles }))
    }
  }

  const removeRole = (selectedRoleId: string) => {
    if (selectedRoleId && selectedUser.roles) {
      const newRoles: Role[] = selectedUser.roles.filter(r => r.id !== selectedRoleId)
      dispatch(setSelectedUser({ ...selectedUser, roles: newRoles }))
    }
  }

  const toggleRole = (id: string) => {
    console.log('fdsfdsfds')
    if (isRoleSelected(id)) {
      console.log('remove ' + id)
      removeRole(id)
    } else {
      console.log('add ' + id)
      addRole(id)
    }
  }

  const setDefaultRoles = () => {
    dispatch(setSelectedUser({ ...selectedUser, roles: allRoles.filter(r => r.isDefaultRole) }))
  }

  useEffect(() => {
    if (selectedUser && !selectedUser.id?.length && !selectedUser.roles) {
      setDefaultRoles()
    }
  }, [selectedUser])

  const linkClick = (id: string) => (e: React.MouseEvent): void => {
    toggleRole(id)
    e.preventDefault()
    e.stopPropagation()
  }

  const switchClick = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleRole(id)
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className="columns">
      <div className="column is-full" style={{paddingBottom: 0}}>
        <h1 className="contact-list-header">Roles</h1>
        <div>
          <div>
            <div className="field">
              <div className="control">
                <input ref={ref} className="input is-small" type="text" placeholder="filter" onChange={e => setRoleNameFilter(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="tabs">
            <ul>
              <li className={!selectedRolesFilterEnabled ? 'is-active': ''}><a href='#' onClick={() => setSelectedRolesFilter(false)}>All</a></li>
              <li> | </li>
              <li className={selectedRolesFilterEnabled ? 'is-active': ''}><a href='#' onClick={() => setSelectedRolesFilter(true)}>Selected</a></li>
            </ul>
          </div>
        </div>
        <div className="contact-list-scroll-box">
          <aside className="menu">
            <ul className="menu-list">
              {filteredRoles.map(role =>
                <li key={role.id} className="roleRow">
                  <a href="#" onClick={linkClick(role.id)}>
                    <input id="blocked" type="checkbox" name="blocked" className="switch is-rounded is-success" checked={isRoleSelected(role.id)} onChange={() => switchClick(role.id)} />
                    <label htmlFor="blocked" className="label" style={{ verticalAlign: 'middle' }} />
                    <span>{role.name}</span>
                    {role.isDefaultRole && <button className="button rowRemoveButton" disabled={true}><span onClick={() => switchClick(role.id)}>Default role</span></button>}
                  </a>
                </li>
              )}
            </ul>
          </aside>
        </div>
      </div>
    </div>
  )
}

export default UserRoles
