import { v4 as uuid } from 'uuid'
import { AmUserDTO, Application, Customer, ErrorMessage, LookAndFeelId, Notif, Role, TenantSelect, User } from './types'

export const AUTHENTICATED = 'GOT_STATUS'
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING'
export const GOT_TOKEN = 'GOT_TOKEN'
export const SET_ERROR = 'SET_ERROR'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SET_ALL_PRELOAD_DATA = 'SET_ALL_PRELOAD_DATA'
export const SET_SELECTED_USER = 'SET_SELECTED_USER'
export const SET_USERS = 'SET_USERS'


// Action creators
export const authenticated = (user: User) => {
    console.log("setting authenticated", user)
    return (
    {
        type: AUTHENTICATED,
        payload: user
    } as const
)}

export const setGlobalLoading = (loading: boolean) => (
    {
        type: SET_GLOBAL_LOADING,
        payload: loading
    } as const
)

export const gotToken = (token: string) => (
    {
        type: GOT_TOKEN,
        payload: token
    } as const
)

export const setError = (error: ErrorMessage | undefined) => (
  {
    type: SET_ERROR,
    payload: error
  } as const
)

export const addNotification = ({message, isError = false, isSticky = false }: {message: string, isError?: boolean, isSticky?: boolean}) => (
  {
    type: ADD_NOTIFICATION,
    payload: { id: uuid(), message, visible: true, isError, isSticky }
  } as const
)

export const hideNotification = (notification: Notif) => (
  {
    type: HIDE_NOTIFICATION,
    payload: notification
  } as const
)

export const setAllPreLoadData = (data: [/*AmUserDTO[], */Role[], Customer[], TenantSelect[], Application[], LookAndFeelId[]]) => (
  {
    type: SET_ALL_PRELOAD_DATA,
    payload: data
  } as const
)

export const setSelectedUser = (user: AmUserDTO) => (
  {
    type: SET_SELECTED_USER,
    payload: user
  } as const
)

export const setUsers = (users: AmUserDTO[]) => (
  {
    type: SET_USERS,
    payload: users
  } as const
)
