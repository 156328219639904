import React, { useState, useEffect, useContext } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"
import { Auth0Client, PopupLoginOptions, RedirectLoginResult, getIdTokenClaimsOptions, IdToken, RedirectLoginOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions, LogoutOptions, Auth0ClientOptions } from "@auth0/auth0-spa-js/dist/typings/"
import { DispatchContext } from './context'
import { setGlobalLoading } from './actions'

interface IAuth0Context {
  isAuthenticated: boolean
  user: any
  loading: boolean
  popupOpen: boolean
  loginWithPopup(options: PopupLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
}

interface Auth0ProviderOptions {
  children: React.ReactElement
  onRedirectCallback?(result: RedirectLoginResult): void
}

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<IAuth0Context | null>(null)

export const useAuth0 = () => useContext(Auth0Context)!

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
  const dispatch = React.useContext(DispatchContext)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<any>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client(initOptions)

        setAuth0(auth0FromHook)

        try {
          if (window.location.search.includes("code=")) {
            const { appState } = await auth0FromHook.handleRedirectCallback()
            console.log('returned from auth0')
            onRedirectCallback(appState)
          }
        } catch (err) {
          console.log('err', err.message)
          window.location.href = '/?error=' + encodeURIComponent("Error") + "&error_description=" + encodeURIComponent(err.message)
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated()
        if (!isAuthenticated) {
          console.log('Setting global loader off in authentication handler')
          dispatch(setGlobalLoading(false))
        }

        setIsAuthenticated(isAuthenticated)

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser()
          setUser(user)
        }

        setLoading(false)
      } catch(error) {
        console.log(error)
      }
    }
    initAuth0()

    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (o: PopupLoginOptions) => {
    setPopupOpen(true)

    try {
      await auth0Client!.loginWithPopup(o)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }

    const user = await auth0Client!.getUser()
    setUser(user)
    setIsAuthenticated(true)

    console.log("handleRedirectCallback", user)
  };

  const handleRedirectCallback = async () => {
    setLoading(true)
    const result = await auth0Client!.handleRedirectCallback()
    const user = await auth0Client!.getUser()
    console.log("handleRedirectCallback", result, user)

    setIsAuthenticated(true)
    setUser(user)
    setLoading(false)

    return result
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) =>
          auth0Client!.getIdTokenClaims(o),
        loginWithRedirect: (o: RedirectLoginOptions) =>
          auth0Client!.loginWithRedirect(o),
        getTokenSilently: (o: GetTokenSilentlyOptions | undefined) =>
          auth0Client!.getTokenSilently(o),
        getTokenWithPopup: (o: GetTokenWithPopupOptions | undefined) =>
          auth0Client!.getTokenWithPopup(o),
        logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o)
      }}
    >
      {children}
    </Auth0Context.Provider>
  )

}
