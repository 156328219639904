export interface UserMetadata {
  superAdmin?: boolean
  phone_number?: string
  ssn?: string
  pin?: string
  oid?: string
  invitation_login_url?: string
  // verification_code?: string
}

export interface AppMetadata {
  suggestStrongAuthentication?: boolean
  last_ip?: string
  last_login?: string
  logins_count?: number
  tenantname?: string
}

export interface TransientUserData {
  status?: string | undefined
  accountType?: string | undefined
  invitationApplication?: string | undefined
}

export interface UserIdentity {
  provider: string
  connection: string
  isSocial: boolean
  user_id: string
}

export interface User {
  user_id?: string
  name: string
  created_at?: string
  email: string
  email_verified?: boolean
  nickname?: string
  picture?: string
  updated_at?: string
  user_metadata?: UserMetadata
  last_login?: string
  last_ip?: string
  logins_count?: number
  app_metadata?: AppMetadata
  blocked?: boolean
  identities?: Array<UserIdentity>
}

export interface Role {
  id: string
  name: string
  description?: string
  isDefaultRole?: boolean
}

export interface SelectableRole extends Role {
  selected: boolean
}

export interface UserResults {
  start: number
  limit: number
  length: number
  total: number
  users: User[]
}

export const newUser = (): AmUserDTO => {
  return {
    id: '',
    name: "",
    emailVerified: false,
    email: "",
    phoneNumber: '',
    tenantId: '',
    blocked: false,
    canReInvite: false,
    canReSendPin: false,
    accessContacts: [],
    originalAccessContacts: [],
    shouldSendInvitation: true
  }
}

export interface Customer {
  id: number
  name: string
  portfolioIDs: Array<string>
  portfolios?: Array<Portfolio>
  contactCode: string | undefined
  contactTypeName: string | undefined
  contactSubTypeName: string | undefined
}

export interface Portfolio {
  id: string
  name: string
}

export interface AccessContact {
  contactId: number | undefined
  viewer: boolean
  admin: boolean
  whitelist: Array<string> | null
  contactName?: string
  ssrsReportId: string | undefined
  reportDirectory: string | undefined
  customReportDirectory: string | undefined
  portfolioAnalyticsDirectory: string | undefined
  privateAssetsDirectory: string | undefined
  esgDirectory: string | undefined
  riskAnalysisDirectory: string | undefined
  securityAnalyticsDirectory: string | undefined
  accountingDirectory: string | undefined
  peerGroupDirectory: string | undefined
  otherDirectory: string | undefined
  reportBundleDirectory: string | undefined
}

export type ReportingDirectoryField = keyof Pick<AccessContact, 'reportDirectory' | 'customReportDirectory' | 'portfolioAnalyticsDirectory' | 'privateAssetsDirectory' | 'esgDirectory' | 'riskAnalysisDirectory' | 'securityAnalyticsDirectory' | 'accountingDirectory' | 'peerGroupDirectory' | 'otherDirectory' | 'reportBundleDirectory'>

export interface AccessAccess {
  contacts: Array<AccessContact>
}

export interface Access {
  user_id: string
  access: AccessAccess
}

export interface AccountLink {
  pin: string
  code: string
}

export interface ErrorMessage {
  title: string
  description?: string | string[] | null
}

export interface TenantSelect {
  label: string
  tenantName: string
  defaultSsrsReportId?: string | undefined
  defaultReportDirectory?: string | undefined
  defaultCustomReportDirectory?: string | undefined
  defaultPortfolioAnalyticsDirectory?: string | undefined
  defaultPrivateAssetsDirectory?: string | undefined
  defaultEsgDirectory?: string | undefined
  defaultRiskAnalysisDirectory?: string | undefined
  defaultSecurityAnalyticsDirectory?: string | undefined
  defaultAccountingDirectory?: string | undefined
  defaultPeerGroupDirectory?: string | undefined
  defaultOtherDirectory?: string | undefined
  defaultReportBundleDirectory?: string | undefined
}

export interface Notif {
  id: string
  message: string
  visible: boolean
  isError?: boolean
  isSticky?: boolean
}

export interface Application {
  name: string
  url: string
}

export type LookAndFeelId = string

export interface AmUserBaseDTO {
  id: string
  name: string
  email: string
  emailVerified: boolean
  blocked: boolean
  phoneNumber: string
  tenantId: string
  invitationApplicationName?: string
  lastIp?: string
  lastLogin?: string
  lastUpdate?: string
  loginsCount?: number
  accountType?: string
  status?: string
  canReInvite: boolean
  canReSendPin: boolean
  created?: string
  title?: string
  invitationLink?: string
}

export interface AmUserDTO extends AmUserBaseDTO {
  accessContacts: AccessContact[]
  roles?: Role[]
  originalAccessContacts: AccessContact[]
  originalName?: string
  shouldSendInvitation?: boolean
}

export type TableSortField = keyof AmUserDTO

export enum TableSortDirection {
  ASC = 1,
  DESC = -1
}

export interface TableSorting {
  field: TableSortField
  direction: TableSortDirection
}

