import React, { useContext, useEffect, useState } from 'react'
import query from 'query-string'
import { DispatchContext, StateContext } from '../common/context'
import { useAuth0 } from '../common/auth0'
import { initializeLink, linkAccount } from '../common/api'
import ErrorPage from './Error'
import { setError } from '../common/actions'

interface LStorageI {
  code: string
  email: string
  username: string
  pin: string
}

type RouteParams = {
}

const setValues = (val: LStorageI) => {
  localStorage.setItem('verify', JSON.stringify(val))
}

const getValues = (): LStorageI | undefined => {
  // Try to get values from local storage
  let val = localStorage.getItem('verify')
  if (!val) return undefined
  return JSON.parse(val)
}
const cleanLocalStorage = () => {
  localStorage.removeItem('verify')
  localStorage.removeItem('returnToLink')
  localStorage.removeItem('completed')
}

const setReturnTo = (returnTo: any): void => {
  if (returnTo) {
    localStorage.setItem('returnToLink', process.env.REACT_APP_AD_LOGOUT_URI ? `${process.env.REACT_APP_AD_LOGOUT_URI}?post_logout_redirect_uri=${returnTo}` : returnTo)
  }
}



const LinkStrongID = (props: RouteParams): React.ReactElement => {
  const { error } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [verifying, setVerifying] = useState<boolean>(false)
  const [token, setToken] = useState<string | undefined>()
  const { loginWithRedirect, loading, user, getTokenSilently, logout } = useAuth0()
  // const [error, setError] = useState<ErrorMessage | undefined>()
  const [completed, setCompleted] = useState<boolean>(false)
  const [localLoading, setLocalLoading] = useState<boolean>(false)

  const logOutWhenSuccess = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    const returnTo = localStorage.getItem('returnToLink') || process.env.REACT_APP_PORTAL_URI
    logout({ returnTo })
    cleanLocalStorage()
  }

  const navigateBackToPortal = () => {
    cleanLocalStorage()
    if (process.env.REACT_APP_PORTAL_URI) {
      window.location.replace(process.env.REACT_APP_PORTAL_URI)
    }
  }

  const vals = getValues()

  const onVerifyClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (token) {
      setVerifying(true)
      initializeLink(token).then(ret => {
        setValues({ email: user.email, username: user.sub, pin: ret.pin, code: ret.code })
        logout({ returnTo: window.location.protocol + '//' + window.location.host + window.location.pathname })
        setVerifying(false)
      }).catch(async (err) => {
        console.log('init link error', err)
        const description = err.response ? await err.response.text() + '. Please contact support.' : 'Please contact support'
        dispatch(setError({ title: 'Error', description }))
        setVerifying(false)
      })
    }
  }

  // Handle completion state persistance to avoid redirects if user refreshes page after successful activation
  useEffect(() => {
    console.log('useEffect "completed"')
    if (!completed) {
      const storedCompleted = localStorage.getItem('completed')
      // This is not a circular call since useEffect is only called if the value actually changes
      setCompleted(!!storedCompleted)
    } else {
      localStorage.setItem('completed', 'true')
    }
  }, [completed])

  useEffect(() => {
    console.log('user', user)
    setReturnTo(query.parse(window.location.search)?.returnTo);
    if (user) {
      getTokenSilently().then(tok => {
        //console.log('getTokenSilently', tok)
        setToken(tok)

        // Handle linking of accounts after returning from strong auth
        if (tok && vals && user.sub.includes('samlp|Pankkitunnistus')) {
          setLocalLoading(true)
          linkAccount(tok, vals.username, vals.code, vals.pin).then(ret => {
            console.log('linkAccounts', ret)
            setCompleted(true)
          }).catch(async (err) => {
            console.log('linkAccounts error', err)
            cleanLocalStorage()
            const errorMessage = 'Account linking failed'
            const errorDescription = err.response ? await err.response.text() + '. Please contact support.' : 'Please contact support'
            logout({ federated: true, returnTo: `${window.origin}${window.location.pathname}?error=${encodeURIComponent(errorMessage)}&error_description=${encodeURIComponent(errorDescription)}` })
            dispatch(setError({ title: errorMessage, description: errorDescription }))
          }).finally(() => {
            setLocalLoading(false)
          })
        }
      }).catch(err => {
        console.log('getTokenSilently error', err)
      })
    }

    // eslint-disable-next-line
  }, [user])

  if (loading || localLoading) {
    return (<progress className="progress is-small is-primary" max="100">loading...</progress>)
  }

  if (error) {

    return (
      <ErrorPage error={error.title}>
        <div>{error.description}</div>
        <div>
          {process.env.REACT_APP_PORTAL_URI &&
            <button style={{marginTop: '1rem'}} onClick={navigateBackToPortal} className="button is-small">Return to Application</button>
          }
        </div>
      </ErrorPage>
    )
  }

  // No values are stored, make sure we have the original AD session
  if (!user && !vals) {
    // Clean completion status from localstorage in case the user has been here and has closed the browser window before local storage cleanup
    localStorage.removeItem('completed')
    loginWithRedirect({
      redirect_uri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
      connection:'JAMAdvisors'
    })
    return (<div>Redirecting to login...</div>)
  }

  // We have initialized the original user with values and removed the AD session and should proceed to strong auth
  if (!user && vals && !completed) {
    // loginWithRedirect({ redirect_uri: window.location.protocol + '//' + window.location.host + window.location.pathname })
    loginWithRedirect({
      redirect_uri: window.location.protocol + '//' + window.location.host + window.location.pathname,
      connection: 'PankkitunnistusPROD'
    })
    return (<div>Redirecting to login...</div>)
  }

  if (completed) {
    return (<div className="container activate-container">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            <img src={process.env.PUBLIC_URL + '/favicons/favicon-2-32x32.png'} alt="logo" width="32" height="32" style={{ marginRight: '10px' }} />
            Strong authentication is now activated.
          </p>
        </header>
        <div className="card-content">
          <div className="columns">
            <div className="column">
              <button onClick={logOutWhenSuccess} className="button is-small">Return to Application</button>
            </div>
          </div>
        </div>
      </div>
    </div>)

  } else {

    return (
      <div className="container activate-container">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <img src={process.env.PUBLIC_URL + '/favicons/favicon-2-32x32.png'} alt="logo" width="32" height="32" style={{ marginRight: '10px' }} />
              Enable strong authentication for: {user.name}
            </p>
          </header>
          <div className="card-content">
            <div className="columns">
              <div className="column">
                <button onClick={onVerifyClick} disabled={verifying} className="button is-small">Enable</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LinkStrongID
