import React, { CSSProperties, ReactNode, useContext, useEffect, useState } from 'react'
import * as R from 'ramda'
import { AmUserDTO, newUser, TenantSelect } from '../../common/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAt,
  faComputer,
  faCopy,
  faHardHat,
  faHotel,
  faLink,
  faPhone,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { DispatchContext, StateContext } from '../../common/context'
import { addNotification, setSelectedUser } from '../../common/actions'
import { canInviteUser, capitalize, formatDate } from '../../common/helper'
import * as api from '../../common/api'
import { useFeatures } from '../../common/useFeatures'

const smsStatusLabel = 'SMS status:'

const UserDetails = (props: { isSelectedTab: boolean; deleteUser: () => void }): React.ReactElement => {
  const dispatch = useContext(DispatchContext)
  const { token, superAdmin, userErrors, selectedUser, allTenants, allApplications } = useContext(StateContext)
  const [tenants, setTenants] = useState<TenantSelect[]>([])
  // We are using a local state object so that not every key stroke will update the global context
  const [user, setUser] = useState<AmUserDTO>(newUser())
  const [smsStatus, setSmsStatus] = useState<string | undefined>(undefined)
  const features = useFeatures()

  const ref: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)
  const invitationLinkRef: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)

  /* There could be cases where the current user has a tenant set which has been hidden or removed */
  const loadTenantsWithMissingTenantSupport = () => {
    if (selectedUser.tenantId) {
      const hasCurrentTenant = allTenants.find(t => selectedUser.tenantId === t.tenantName)
      if (hasCurrentTenant) {
        setTenants(allTenants)
      } else {
        setTenants([...allTenants, { label: selectedUser.tenantId, tenantName: selectedUser.tenantId }])
      }
    } else {
      setTenants(allTenants)
    }
  }

  const loadSMSStatus = async () => {
    try {
      const logEntry = await api.getSMSStatus(token, selectedUser.id)
      const status = logEntry?.status ? `${smsStatusLabel} ${capitalize(logEntry.status)} on ${formatDate(logEntry.dateSent)}` : 'No sms sent'
      setSmsStatus(status)
    } catch (error) {
      setSmsStatus(`${smsStatusLabel} Error while fetching status`)
    }
  }

  useEffect(() => {
    if (!selectedUser?.id) ref.current?.focus()
  },[])

  useEffect(() => {
    if (user) {
      const timer = setTimeout(() => {
        console.log('dispatching changes from user details form to context')
        dispatch(setSelectedUser(user))
      }, 700)
      return () => clearTimeout(timer)
    }
  }, [user])

  useEffect(() => {
    if (!R.equals(selectedUser, user)) {
      console.log('setting locale details user')
      setUser(selectedUser)
      if (selectedUser && allTenants) {
        loadTenantsWithMissingTenantSupport()
      }
    }
    if (isWaitingForActivation() && !smsStatus) {
      loadSMSStatus()
    }
  },[selectedUser, allTenants])

  const isWaitingForActivation = (): boolean => selectedUser?.status === 'Waiting for activation'

  /* There could be users that are from other federated IAM systems and in these cases we can't allow editing some fields */
  const isInputDisabled = (user: AmUserDTO): boolean => !(!user || !user.id || user.id.startsWith('auth0'))

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    switch (target.name) {
      case "name":
        setUser({ ...user, name: target.value })
        break
      case "email":
        setUser({ ...selectedUser, email: target.value })
        break
      case "phone_number":
        if (target.value.startsWith('0')) {
          target.value = `+358 ${target.value.substring(1)}`
        }
        setUser({ ...selectedUser, phoneNumber: target.value })
        break
      case "blocked":
        setUser({ ...selectedUser, blocked: target.checked })
        break
      case "title":
        setUser({ ...selectedUser, title: target.value })
        break
      case "shouldSendInvitation":
        setUser({ ...selectedUser, shouldSendInvitation: target.checked })
        break
      default:
        console.log("unknown input change", target)
        return
    }
  }

  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.name) {
      case "tenantname":
        dispatch(setSelectedUser({ ...selectedUser, tenantId: event.target.value }))
        break
      case "invitationApplication":
        dispatch(setSelectedUser({ ...selectedUser, invitationApplicationName: event.target.value }))
        break
      default:
        console.log("unknown input change", event.target)
        return
    }
  }

  const copyInvitationLinkToClipboard = async () => {
    const value = invitationLinkRef.current?.value || ''
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(value);
    } else {
      document.execCommand('copy', true, value);
    }
    dispatch(addNotification({ message: 'Link copied to clipboard' }))
  }

  return (
    <div className="userDetails">
      <div className="field">
        <label className="label">Name</label>
        <div className="control has-icons-left has-icons-right">
          <input
            ref={ref}
            className={'input' + (userErrors.includes('name') ? ' is-danger' : '')}
            type="text" placeholder="Enter Name" name="name" defaultValue={selectedUser.name}
            onChange={onChangeInput} disabled={isInputDisabled(selectedUser)}
            onBlur={(e) => {
            }}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faUser} />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </div>
      </div>

      <div className="field">
        <label className="label">Email</label>
        {!!selectedUser.id?.length &&
          <FieldNotice style={{ marginTop: '-12px' }}>Note: Changing the email address currently requires more work, if
            needed contact IT</FieldNotice>}
        <div className="control has-icons-left has-icons-right">
          <input className={'input' + (userErrors.includes('email') ? ' is-danger' : '')} type="text"
                 placeholder="Enter Email Address" name="email" defaultValue={selectedUser.email}
                 onChange={onChangeInput} disabled={isInputDisabled(selectedUser)} />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faAt} />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </div>
      </div>

      <div className="field">
        <label className="label">Phone</label>
        <div className="control has-icons-left has-icons-right">
          <input className={'input' + (userErrors.includes('phone_number') ? ' is-danger' : '')} type="text"
                 placeholder="Enter Phone Number with country code" name="phone_number"
                 defaultValue={selectedUser.phoneNumber} onChange={onChangeInput} />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faPhone} />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </div>
        {isWaitingForActivation() && smsStatus !== undefined &&
          <FieldNotice>{smsStatus}</FieldNotice>
        }
        {isWaitingForActivation() && smsStatus === undefined &&
          <FieldNotice className="is-loading">{smsStatusLabel}</FieldNotice>
        }
      </div>

      <div className="field">
        <label className="label">Title</label>
        <div className="control has-icons-left has-icons-right">
          <input className="input" type="text" placeholder="Enter work title (optional)" name="title"
                 defaultValue={selectedUser.title} onChange={onChangeInput} />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faHardHat} />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </div>
      </div>

      {superAdmin && tenants && tenants.length > 1 &&
        <div className="field">
          <label className="label">Tenant</label>
          <div className="control has-icons-left has-icons-right">
            <div className={"select" + (userErrors.includes('tenantname') ? ' is-danger' : '')}>
              <select name="tenantname" onChange={onChangeSelect} value={selectedUser.tenantId}>
                {tenants.map(tenant =>
                  <option value={tenant.tenantName} key={tenant.label}>{tenant.label}</option>
                )}
              </select>
            </div>
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faHotel} />
            </span>
            <span className="icon is-small is-right">
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>
      }
      {superAdmin && allApplications.length > 1 &&
        <div className="field">
          <label className="label">Application to redirect to from invitation</label>
          <div className="control has-icons-left has-icons-right">
            <div className={"select" + (userErrors.includes('invitationApplication') ? ' is-danger' : '')}>
              <select disabled={!canInviteUser(selectedUser)} name="invitationApplication" onChange={onChangeSelect}
                      value={selectedUser.invitationApplicationName}>
                <option value={""}>Select application</option>
                {allApplications.map(app =>
                  <option value={app.name} key={app.name}>{app.name}</option>
                )}
              </select>
            </div>
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faComputer} />
            </span>
            <span className="icon is-small is-right">
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>
      }

      <div className="field">
        <label className="label">Block user from signing in to any application</label>
        <input id="blocked" type="checkbox" name="blocked" className="switch is-rounded is-danger"
               checked={!!selectedUser.blocked} onChange={onChangeInput} />
        <label htmlFor="blocked" className="label" />
      </div>

      {!user.id?.length &&
        <div className="field">
          <label className="label">Send invitation</label>
          <input id="shouldSendInvitation" type="checkbox" name="shouldSendInvitation" className="switch is-rounded"
                 checked={!!selectedUser.shouldSendInvitation} onChange={onChangeInput} />
          <label htmlFor="shouldSendInvitation" className="label" />
        </div>
      }

      {!!user.id?.length && features.featureDeleteUser && (
        <button className="button" onClick={props.deleteUser}>Delete user</button>
      )}

      {selectedUser.invitationLink && (
        <div className="field">
          <label className="label">Invitation link <FontAwesomeIcon icon={faCopy} size={'sm'} style={{ marginLeft: '5px', cursor: 'pointer' }} className="has-text-link" onClick={copyInvitationLinkToClipboard} /></label>
          <div className="control has-icons-left has-icons-right">
            <input ref={invitationLinkRef} className="input" type="text" placeholder="Invitation link" name="invitationLink"
                   value={selectedUser.invitationLink} />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faLink} />
            </span>
            <span className="icon is-small is-right">
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

const FieldNotice = ({ children, style, className }: {
  children: ReactNode,
  style?: CSSProperties,
  className?: string | undefined
}) => <div style={{
  ...(style || {}),
  marginBottom: '5px',
  fontStyle: 'italic',
  fontSize: '14px'
}} className={`fieldNotice ${className || ''}`}>{children}</div>

export default UserDetails
