import React, { useEffect } from 'react'

const SearchInput = (props: { onChange: (str: string) => void, autoFocus?: boolean, searchLoading: boolean, disabled: boolean }) => {
  const ref: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props.autoFocus && !props.disabled) ref.current?.focus()
  }, [props.autoFocus, props.disabled])

  /*
  useEffect(() => {
    if (text !== undefined) {
      const timer = setTimeout(() => {
        props.onChange(text)
      }, 700)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [text])
  */

  const search = () => {
    console.log(ref.current?.value)
    props.onChange(ref.current?.value || '')
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      console.log(ref.current?.value)
      search()
    }
  }

  return (
    <>
      <div className={"control" + (props.searchLoading ? ' is-loading' : '')} style={{ width: "100%", maxWidth: '400px' }}>
        <input ref={ref} disabled={props.disabled} className="input" type="search" name="search" autoComplete="off" placeholder="filter name, email and tenant etc." onKeyPress={onKeyPress} />
      </div>
      <div className="control">
        <button className="button is-info" onClick={search} disabled={props.disabled}>
          Search
        </button>
      </div>
    </>
  )
}

export default SearchInput
