import ky, { ResponsePromise } from 'ky'
import { AccountLink, AmUserDTO, Application, Customer, LookAndFeelId, Portfolio, Role, TenantSelect } from './types'

const api = ky.create({ prefixUrl: `/api/v1/`, timeout: 60000 })

const withAuth = (token: string): typeof ky => api.extend({
  hooks: {
    beforeRequest: [
      (request): void => {
        request.headers.set('Authorization', "Bearer " + token)
        request.headers.set('Content-Type', "application/json")
      },
    ],
  },
})

const withoutAuth = (): typeof ky => api.extend({
  hooks: {
    beforeRequest: [
      (request): void => {
        request.headers.set('Content-Type', "application/json")
      },
    ],
  },
})
// We are using a JSON parse stringify to remove undefined values from the query string, otherwise they will be sent as strings
export const getUsers = (token: string, filter?: string, roleFilter?: string): Promise<AmUserDTO[] | { error: string }> => withAuth(token).get('user', { searchParams: JSON.parse(JSON.stringify({ filter, roleFilter })) }).json()
export const getUser = (token: string, userId: string): Promise<AmUserDTO> => withAuth(token).get(`user/${userId}`).json()
export const getSMSStatus = (token: string, userId: string): Promise<{ status: string; dateSent: Date }> => withAuth(token).get(`user/${userId}/smsstatus`).json()
export const saveUser = (token: string, userID: string, user: AmUserDTO): Promise<AmUserDTO> => withAuth(token).put('user/' + userID, { body: JSON.stringify(user) }).json()
export const createUser = (token: string, user: AmUserDTO, shouldSendInvitation: boolean = true): Promise<AmUserDTO> => withAuth(token).post('user', { body: JSON.stringify(user) }).json()
export const deleteUser = (token: string, userID: string): Promise<Response> => withAuth(token).delete('user/' + userID)
export const getTenants = (token: string): Promise<TenantSelect[]> => withAuth(token).get('tenants').json()
export const getApplications = (token: string): Promise<Application[]> => withAuth(token).get('applications').json()

export const getPortfolios = (token: string, customerID?: number): Promise<Array<Portfolio>> => withAuth(token).get('portfolio', { searchParams: { cid: customerID! } }).json()
export const getCustomers = (token: string): Promise<Array<Customer>> => withAuth(token).get('customer').json()
export const getAllRoles = (token: string): Promise<Array<Role>> => withAuth(token).get(`roles`).json()
export const getLookAndFeelIds = (token: string): Promise<Array<LookAndFeelId>> => withAuth(token).get(`lookandfeels`).json()

export const authorize = (): ResponsePromise => api.get('auth/authorize')
export const login = (): Promise<Response> => api.get('auth/login', { "redirect": "manual" })
export const check = (token: string): Promise<Response> => withAuth(token).get('auth/check').json()
export const verifyAccount = (code: string, pin: string): ResponsePromise => withoutAuth().post('auth/verify', { body: JSON.stringify({ code: code, pin: pin }) })
export const linkAccount = (token: string, username: string, code: string, pin: string): Promise<{ url: string }> => withAuth(token).post('auth/link', { body: JSON.stringify({ username: username, code: code, pin: pin }) }).json()
export const initializeLink = (token: string): Promise<AccountLink> => withAuth(token).get('auth/link').json()
export const sendInvitation = (token: string, userID: string, user: AmUserDTO): Promise<AmUserDTO> => withAuth(token).post('user/' + userID + '/invite', { body: JSON.stringify(user) }).json()
export const sendPinCode = (token: string, userID: string): Promise<AmUserDTO> => withAuth(token).post('user/' + userID + '/sendpin').json()
export const requestPasswordTicket = (code: string, pin: string): Promise<{ ticket: string }> => withoutAuth().post('auth/password/ticket', { body: JSON.stringify({ code, pin }) }).json()
export const finishTotp = (code: string, pin: string): Promise<{ url: string }> => withoutAuth().post('auth/totp/finish', { body: JSON.stringify({ code, pin }) }).json()
